import { ISellerDetails, ISellerState, States } from "@/utils/interfaces"
import { Module } from "vuex"
import { SET_USER_DETAILS, SET_VALID } from "../mutationsTypes"

export const sellers: Module<ISellerState, States> = {
	mutations: {
		[SET_USER_DETAILS](state, details: ISellerDetails) {
			state.sellers = details
		},

		[SET_VALID](state, newValue: boolean) {
			state.isNewOpportunityFormValid = newValue
		},
	},
}
