import { IProduct } from "./interfaces"

export function calculateEstimateValue(products: IProduct[]) {
	const estimateValue = products.reduce((acc, product) => {
		if (!product.competition) {
			return acc + product?.amount * product?.price
		}
		return acc
	}, 0)
	return estimateValue
}
