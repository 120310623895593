export const SET_USER_DETAILS = "SET_USER_DETAILS"
export const GET_DASH_OPPORTUNITIES = "GET_DASH_OPPORTUNITIES"
export const SET_DASH_OPPORTUNITIES = "SET_DASH_OPPORTUNITIES"
export const SET_DASH_OPPORTUNITIES_APPROVED = "SET_DASH_OPPORTUNITIES_APPROVED"
export const SET_ALL_DASH_OPPORTUNITIES = "SET_ALL_DASH_OPPORTUNITIES"
export const SET_TOTAL_OPPORTUNITIES = "SET_TOTAL_OPPORTUNITIES"
export const SET_CHART_VALUES = "SET_CHART_VALUES"
export const SET_OPPORTUNITY_DETAILS = "SET_OPPORTUNITY_DETAILS"
export const SET_VALID = "SET_VALID"

export const GET_OPPORTUNITIES = "GET_OPPORTUNITIES"

export const SET_OPPORTUNITY = "SET_OPPORTUNITY"
export const SET_OPPORTUNITY_CREATED_ASC = "SET_OPPORTUNITY_CREATED_ASC"
export const SET_OPPORTUNITY_CREATED_DSC = "SET_OPPORTUNITY_CREATED_DSC"

export const SET_OPPORTUNITY_ESTIMATED_ASC = "SET_OPPORTUNITY_ESTIMATED_ASC"
export const SET_OPPORTUNITY_ESTIMATED_DSC = "SET_OPPORTUNITY_ESTIMATED_DSC"

export const SET_FILTER_OPPORTUNITY = "SET_FILTER_OPPORTUNITY"

export const SET_ERROR = "SET_ERROR"

export const GET_RESALES = "GET_RESALES"
export const SET_RESALE = "SET_RESALE"

export const SET_RESALE_CREATED_ASC = "SET_RESALE_CREATED_ASC"
export const SET_RESALE_CREATED_DSC = "SET_RESALE_CREATED_DSC"

export const SET_401_ERROR = "SET_401_ERROR"
