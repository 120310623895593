import styled from "vue3-styled-components"

const color = { idBtn: Boolean, color: String, background: String }

export const MainButton = styled.button`
	box-shadow: 0 0.3rem 0.62rem #e0e4ee;
	background: #fff;
	border-radius: 0.3rem;
	width: 21.87rem;
	height: 3.75rem;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
`
export const DeleteButton = styled.button`
	box-shadow: 0 0.3rem 0.62rem #e0e4ee;
	background: #fb3f61;
	border-radius: 0.3rem;
	width: 21.87rem;
	margin: 0 auto
	height: 3.75rem;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold
	:hover {
		transform: scale(1.05);
	}
`

export const ModalButton = styled.button`
	box-shadow: 0 0.3rem 0.62rem #e0e4ee;
	border-radius: 0.3rem;
	width: 16.87rem;
	height: 2.5rem;
	color: #fff;
	:active {
		transform: scale(0.9);
	}
`
export const AuxiliaryBtn = styled("button", color)`
	color: ${(props) => (props?.idBtn ? (props?.color as string) : "#b5bfc9")};
	position: relative;
	:active {
		transform: scale(0.9);
	}
`

export const AuxiliaryAnchor = styled("a", color)`
	color: ${(props) => (props?.idBtn ? (props?.color as string) : "#b5bfc9")};
	position: relative;
	:active {
		transform: scale(0.9);
	}
`
export const MoreStatusBtn = styled("button", color)`
	color: ${(props) => (props?.idBtn ? (props?.color as string) : "#b5bfc9")};
	position: relative
	:active {
		transform: scale(0.9);
	}
	display: flex;
    justify-content: flex-start;
	grid-gap: 0.5rem;
    text-align: left;
`

export const CalendarBtn = styled("button", color)`
	color: ${(props) => (props?.idBtn ? (props?.color as string) : "#b5bfc9")};
	position: relative;
`

export const FilterBtn = styled("button", color)`
	color: ${(props) => (props?.idBtn ? (props?.color as string) : "#b5bfc9")};
	position: relative;
`

export const PaginationButton = styled("button", color)`
	width: 32px;
	height: 32px;
	background: ${(props) =>
		props?.idBtn ? (props?.background as string) : "#fff"};
	color: ${(props) => (props?.idBtn ? (props?.color as string) : "#000")};
	border-radius: 8px;
`
export const ApproveButton = styled.button`
	width: 7.5rem;
	height: 2.5rem;
	background: #15d177;
	border-radius: 0.5rem;
	color: #fff;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	:active {
		transform: scale(0.9);
	}
`

export const IconButton = styled.button`
	width: 7.5rem; // 185px
	height: 2.5rem;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	border-radius: 0.5rem;
	color: #fff;
	:active {
		transform: scale(0.9);
	}
`

export const IconApproveButton = styled.button`
	width: 11.5625rem; // 185px
	height: 2.5rem;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	border-radius: 0.5rem;
	color: #fff;
	:active {
		transform: scale(0.9);
	}
`

export const DenyButton = styled.button`
	width: 7.5rem;
	height: 2.5rem;
	background: #fb3f61;
	border-radius: 0.5rem;
	color: #fff;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	:active {
		transform: scale(0.9);
	}
`
export const DenyButtonModal = styled.button`
	width: 10rem;
	height: 2.5rem;
	background: #fb3f61;
	border-radius: 0.5rem;
	:active {
		transform: scale(0.9);
	}
`
export const ButtonApproveSeller = styled.button`
	width: 28.9rem;
	height: 3.75rem;
	border-radius: 0.5rem;
	color: #fff;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	:active {
		transform: scale(0.9);
	}
`
export const ButtonUpdateUser = styled.button`
	width: 28.9rem;
	height: 3.75rem;
	border-radius: 0.5rem;
	color: #fff;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	:active {
		transform: scale(0.9);
	}
`
export const ButtonListOpportunities = styled.a`
	width: 10.5rem;
	height: 2.4rem;
	border-radius: 0.5rem;
	color: #fff;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	:active {
		transform: scale(0.9);
	}
`
