export enum HeaderResale {
	REVENDA = "REVENDA",
	CNPJ = "CNPJ",
	TELEFONE = "TELEFONE",
	CONTANTO = "CONTATO",
	SOLICITADO = "SOLICITADO EM",
	STATUS = "STATUS",
}

export enum HistoryRoute {
	CREATE_HISTORY = "/history/create",
	GET_HISTORY_TYPEID = "/history/getByTypeId",
}

export enum AuthorizationUser {
	REPRESENTATIVE = "REPRESENTATIVE",
	RESALE = "RESALE",
	SELLER = "SELLER",
	SUPPORT = "SUPPORT",
}

export enum StatusResale {
	EXPIRED = "expired",
	ANALYSIS = "analysis",
	APPROVED = "approved",
	RENOVATION = "renovation",
	DISAPPROVED = "disapproved",
	AWAITING_RENOVATION = "awaiting_renovation",
}

export enum StatusOpportunity {
	EXPIRED = "expired",
	ANALYSIS = "analysis",
	APPROVED = "approved",
	RENOVATION = "renovation",
	DISAPPROVED = "disapproved",
	AWAITING_RENOVATION = "awaiting_renovation",
}

export enum Filter {
	DATE = "calendar",
	MORE = "more",
}

export enum ShowStatusResale {
	EXPIRED = "Expirado",
	APPROVED = "Aprovado",
	ANALYSIS = "Em Análise",
	RENOVATION = "Renovação",
	DISAPPROVED = "Reprovado",
	AWAITING_RENOVATION = "Aguardando Renovação",
}

export enum Months {
	JAN = "Jan",
	FEV = "Fev",
	MAR = "Mar",
	ABR = "Abr",
	MAIO = "Maio",
	JUN = "Jun",
	JUL = "Jul",
	AGO = "Ago",
	SET = "Set",
	OUT = "Out",
	NOV = "Nov",
	DEZ = "Dez",
}

export enum Opportunities {
	RESALE = "Contato Revenda",
	FANTASY_NAME = "Nome fantasia",
	PRODUCT = "Produto",
	TOTAL_ESTIMATED = "Total estimado",
	STATUS = "Status",
}

export enum ShowStatusOpportunity {
	ANALYSIS = "Em Análise",
	APPROVED = "Aprovado",
	DISAPPROVED = "Reprovado",
	EXPIRED = "Expirado",
	AWAITING_RENOVATION = "Aguardando Renovação",
	RENOVATION = "Renovação",
}

export enum ImagesType {
	LOGIN_PRODUCTS = "login_products",
}

export enum EmailError {
	EXISTENT_EMAIL = "Email já está sendo utilizado",
}

export enum OpportunityOrigin {
	// CROSS_SELLING = "Croos",
	// SPONTANEOUS_CALL = "Cold Call",
	// QUOTE_SITE = "CotacaoSite",
	// EXISTING_CUSTOMER = "Existing Customer",
	// AUTO_GENERATED = "Self Generated",
	COLLABORATOR = "Employee",
	PARTNER = "Partner",
	// PUBLIC_RELATIONS = "Public Relations",
	// DIRECT_MAIL = "Direct Mail",
	// CONFERENCE = "Conference",
	FAIR_EVENT = "Trade Show",
	// INTERNET_WEBSITE = "Web Site",
	// HEARD_ABOUT = "Word of mounth",
	EMAIL = "Email",
	// CAMPAIGN = "Campaign",
	PROSPECTION = "Prospect",
	// LANDING_PAGE = "Landing Page",
	// EMAIL_MARKETING = "E-mail Marketing",
	// BUY_PRIME = "Buy Prime",
	LEAD_GROUPO_ARTICO = "LeadGrupoArtico",
	RESALE_WEBSITE = "ResaleWebsite",
	OTHER = "Other",
}

export enum Companies {
	BLUEBIRD = "Bluebird",
	CHAINWAY = "Chainway",
	SEWOO = "Sewoo",
	CASS = "Cass",
	TAS = "Tas",
	TSC = "TSC",
	MOBILEBASE = "Mobilebase",
	POINTMOBILE = "Pointmobile",
	H3C = "H3C",
	UNITECH = "Unitech",
}

export enum BrandsLower {
	TAS = "tas",
	TSC = "tsc",
	H3C = "h3c",
	CASS = "cass",
	SEWOO = "sewoo",
	CHAINWAY = "chainway",
	BLUEBIRD = "bluebird",
	MOBILEBASE = "mobilebase",
	POINTMOBILE = "pointmobile",
	UNITECH = "unitech",
}

export enum EmailCompanies {
	BLUEBIRD = "Bluebird",
	CHAINWAY = "Chainway",
	SEWOO = "Sewoo",
	CASS = "Cass",
	TAS = "Tas",
	TSC = "TSC Printers",
	MOBILEBASE = "Mobilebase",
	POINTMOBILE = "Point Mobile",
	H3C = "H3C",
	UNITECH = "Unitech",
}

export const ArrayCompanies = [
	{
		key: `${Companies.BLUEBIRD}`,
		value: EmailCompanies.BLUEBIRD,
	},
	{ key: `${Companies.CHAINWAY}`, value: EmailCompanies.CHAINWAY },
	{ key: `${Companies.CASS}`, value: EmailCompanies.CASS },
	{ key: `${Companies.H3C}`, value: EmailCompanies.H3C },
	{ key: `${Companies.MOBILEBASE}`, value: EmailCompanies.MOBILEBASE },
	{
		key: `${Companies.POINTMOBILE}`,
		value: EmailCompanies.POINTMOBILE,
	},
	{ key: `${Companies.SEWOO}`, value: EmailCompanies.SEWOO },
	{ key: `${Companies.TAS}`, value: EmailCompanies.TAS },
	{ key: `${Companies.TSC}`, value: EmailCompanies.TSC },
	{ key: `${Companies.UNITECH}`, value: EmailCompanies.UNITECH },
]

export const AuthBrands = [
	{
		key: `${BrandsLower.BLUEBIRD}`,
		value: BrandsLower.BLUEBIRD,
	},
	{ key: `${BrandsLower.CHAINWAY}`, value: BrandsLower.CHAINWAY },
	{ key: `${BrandsLower.CASS}`, value: BrandsLower.CASS },
	{ key: `${BrandsLower.H3C}`, value: BrandsLower.H3C },
	{ key: `${BrandsLower.MOBILEBASE}`, value: BrandsLower.MOBILEBASE },
	{
		key: `${BrandsLower.POINTMOBILE}`,
		value: BrandsLower.POINTMOBILE,
	},
	{ key: `${BrandsLower.SEWOO}`, value: BrandsLower.SEWOO },
	{ key: `${BrandsLower.TAS}`, value: BrandsLower.TAS },
	{ key: `${BrandsLower.TSC}`, value: BrandsLower.TSC },
	{ key: `${BrandsLower.UNITECH}`, value: BrandsLower.UNITECH },
]

export enum Types {
	OPPORTUNITY = "opportunity",
	RESALE = "resale",
	USER = "user",
	CREATE = "create",
	UPDATE = "update",
}
