import { createApp } from "vue"
import { createVuetify } from "vuetify"
import * as components from "vuetify/components"
import * as directives from "vuetify/directives"
import { key, store } from "./store"
import mdiVue from "mdi-vue/v3"
import * as mdijs from "@mdi/js"
import { createVuestic } from "vuestic-ui"
import "vuetify/styles"
import "vuestic-ui/css"
import "vuetify/dist/vuetify.min.css"

import App from "./App.vue"
import router from "./router"
import "./index.css"
import JsonExcel from "vue-json-excel3"
import * as labsComponents from "vuetify/labs/components"

import { aliases, mdi } from "vuetify/iconsets/mdi-svg"
import { mdiAccount } from "@mdi/js"

const vuetify = createVuetify({
	components: {
		...components,
		...labsComponents,
	},
	icons: {
		defaultSet: "mdi",
		aliases: {
			...aliases,
			account: mdiAccount,
		},
		sets: {
			mdi,
		},
	},
	directives,
})

createApp(App)
	.component("downloadExcel", JsonExcel)
	.use(store, key)
	.use(createVuestic())
	.use(vuetify)
	.use(router)
	.use(mdiVue, { icons: mdijs })
	.mount("#app")
