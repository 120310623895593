import Cookies from "js-cookie"

export const MAX = 999999999999

export const setPermission = (permissions: Array<string>) => {
	const cookieNameToken = "user_permissions"
	const cookieValueToken = JSON.stringify(permissions)
	const expiresToken = 7

	return Cookies.set(cookieNameToken, cookieValueToken, {
		expires: expiresToken,
	})
}

export const getPermission = (): Array<string> => {
	return JSON.parse(Cookies.get("user_permissions") || "[]")
}

export const getExp = (): number => {
	const data = Cookies.get("exp")

	if (data) {
		const exp = JSON.parse(data)
		return exp == typeof "number" ? exp : MAX
	}

	return MAX
}

export const removeItems = () => {
	Cookies.remove("token")
	Cookies.remove("exp")
	Cookies.remove("user_permissions")
	Cookies.remove("refresh_token")
	Cookies.remove("keep_connection")
}

export const hasPermission = (permission: string[]) => {
	return getPermission().filter((e) => permission.includes(e)).length > 0
}

export const setIsAuthenticated = (
	token: string,
	exp: number,
	refreshToken: string,
	company: string,
	id: string,
	name: string
) => {
	const cookieNameToken = "token"
	const cookieValueToken = JSON.stringify(token)
	const expiresToken = 7

	Cookies.set(cookieNameToken, cookieValueToken, { expires: expiresToken })

	const cookieNameExp = "exp"
	const cookieValueExp = JSON.stringify(exp)
	const expiresExp = 7

	Cookies.set(cookieNameExp, cookieValueExp, { expires: expiresExp })

	const cookieNameRefresh = "refresh_token"
	const cookieValueRefresh = JSON.stringify(refreshToken)
	const expiresRefresh = 7

	Cookies.set(cookieNameRefresh, cookieValueRefresh, {
		expires: expiresRefresh,
	})

	const cookieNameCompany = "company"
	const cookieValueCompany = JSON.stringify(company)
	const expiresCompany = 7

	Cookies.set(cookieNameCompany, cookieValueCompany, {
		expires: expiresCompany,
	})

	const cookieName = "username"
	const cookieValueName = JSON.stringify(name)
	const expiresName = 7

	Cookies.set(cookieName, cookieValueName, {
		expires: expiresName,
	})

	const cookieNameId = "userid"
	const cookieValueId = JSON.stringify(id)
	const expiresId = 7

	Cookies.set(cookieNameId, cookieValueId, {
		expires: expiresId,
	})

	localStorage.setItem("isLogged", "false")

	return
}

export const setIsAuthenticatedButNotKeepConnect = (
	token: string,
	exp: number,
	company: string,
	id: string,
	name: string
) => {
	const cookieNameToken = "token"
	const cookieValueToken = JSON.stringify(token)
	const expiresToken = 7

	Cookies.set(cookieNameToken, cookieValueToken, { expires: expiresToken })

	const cookieNameExp = "exp"
	const cookieValueExp = JSON.stringify(exp)
	const expiresExp = 7

	Cookies.set(cookieNameExp, cookieValueExp, { expires: expiresExp })

	const cookieNameCompany = "company"
	const cookieValueCompany = JSON.stringify(company)
	const expiresCompany = 7

	Cookies.set(cookieNameCompany, cookieValueCompany, {
		expires: expiresCompany,
	})

	const cookieName = "username"
	const cookieValueName = JSON.stringify(name)
	const expiresName = 7

	Cookies.set(cookieName, cookieValueName, {
		expires: expiresName,
	})

	const cookieNameId = "userid"
	const cookieValueId = JSON.stringify(id)
	const expiresId = 7

	Cookies.set(cookieNameId, cookieValueId, {
		expires: expiresId,
	})

	localStorage.setItem("isLogged", "false")

	return
}

export const isAuthenticated = (): string => {
	return JSON.parse(Cookies.get("token") || "")
}
