import { UserToken } from "@/utils/interfaces"
import { AxiosClient, RefreshTokenInterceptor, ResultResponse } from "./Client"
import { AuthBrands } from "@/utils/enum"
import Cookies from "js-cookie"

let restClient: AxiosClient
let authClient: AxiosClient
let mailClient: AxiosClient

export const setupClient = (
	baseUrl: string | undefined,
	authUrl: string | undefined,
	mailUrl: string | undefined,
	company: string
) => {
	const refreshTokenInterceptor = new RefreshTokenInterceptor()
	restClient = new AxiosClient(baseUrl ? baseUrl : "", [
		refreshTokenInterceptor,
	])

	const currentUrl = company

	currentUrl
		? AuthBrands.find((value: any) => value.key === currentUrl.toLowerCase())
				?.value
		: ""
	authClient = new AxiosClient(
		authUrl
			? `${authUrl}/${
					currentUrl
						? AuthBrands.find(
								(value: any) => value.key === currentUrl.toLowerCase()
						  )?.value
						: ""
			  }`
			: "",
		[refreshTokenInterceptor]
	)

	mailClient = new AxiosClient(mailUrl ? mailUrl : "", [
		refreshTokenInterceptor,
	])
}

// export const setBearerAuthorization = (
// 	client: AxiosInstance,
// 	token: string
// ) => {
// 	client.defaults.headers.common["Authorization"] = `Bearer ${token}`
// }

// export const setBasicAuthorization = (
// 	client: AxiosInstance,
// 	credential: Credential
// ) => {
// 	client.defaults.auth = {
// 		username: credential.username.trim(),
// 		password: credential.password.trim(),
// 	}
// }

export const refreshToken = async (
	baseUrl: string,
	token: string
): Promise<UserToken | any> => {
	try {
		let tokenReplace = ""
		tokenReplace = token.replace(/^"(.*)"$/, "$1")
		const axiosClient = new AxiosClient(baseUrl, [])

		const cookieNameCompany = "company"
		const company = Cookies.get(cookieNameCompany)?.split('"')[1] || ""

		axiosClient.setAuthorizationToken(`Bearer ${tokenReplace}`)
		if (!baseUrl.toLowerCase().includes("authenticator".toLowerCase())) {
			axiosClient.setCompany(company)
		}

		axiosClient.setAuthorizationToken(tokenReplace)

		const result = await axiosClient.get(
			`${company.toLocaleLowerCase()}/auth/refresh_token`,
			{}
		)

		if (result.status === 200) {
			return {
				accessToken: result.data.data.access_token,
				refreshToken: result.data.data.refresh_token,
			}
		}
	} catch (error: any) {
		return new ResultResponse(error.message, error.status)
	}
}

export const useClient = () => restClient
export const useAuthClient = () => authClient
export const useMailClient = () => mailClient
