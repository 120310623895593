import axios, { AxiosResponse } from "axios"
import {
	IContact,
	ICreateOpportunity,
	IModification,
	IOpportunities,
	IRelase,
	IUser,
} from "@/utils/interfaces"
import { setIsAuthenticated, setPermission } from "@/utils/permissions"
import { useAuthClient, useClient, useMailClient } from "@/clients/AxiosClient"
import { EmailError, Types } from "@/utils/enum"
import { MailRepository, Mailer, Message } from "@/clients/MailClient"
import { createHistory } from "@/repositories/history"
import Cookies from "js-cookie"
import { opportunityStatus, probabilityClosing } from "./maps"

export const signin = async (user: IUser) => {
	try {
		const config = {
			method: "get",
			url: `${process.env.VUE_APP_AUTH}/auth/login`,
			auth: { username: user.username.trim(), password: user.password.trim() },
		}

		const res = await axios(config)

		const currentUrl = window.location.hostname
		const company = currentUrl.replace(/[^a-zA-Z0-9]/g, "")

		const token = res.data.data.access_token
		const refresh_token = res.data.data.refresh_token

		useClient().setAuthorizationToken(`Bearer ${token}`)
		useClient().setCompany(company)
		useAuthClient().setAuthorizationToken(`Bearer ${token}`)
		useMailClient().setAuthorizationToken(`Bearer ${token}`)

		const payload = await getPayload()

		const roles = payload.data.data.payload.role.map(
			(value: any) => value.data.brand === company
		)
		const exp = payload.data.data.payload.exp

		setIsAuthenticated(
			token,
			exp,
			refresh_token,
			company,
			payload.data.data.payload.id,
			payload.data.data.payload.name
		)

		setPermission(roles)

		return res
	} catch (error) {
		return error
	}
}

export const getPayload = async () => {
	try {
		const res = await useAuthClient().get("/auth/payload")
		if (res.status >= 400) {
			throw new Error(res.data.message)
		}
		// const config = {
		// 	method: "get",
		// 	url: `${process.env.VUE_APP_AUTH}/auth/payload`,
		// 	headers: {
		// 		Authorization: `Bearer ${token}`,
		// 	},
		// }

		// const res = await axios(config)

		return res
	} catch (error: any) {
		return error
	}
}

export const getAllResales = async () => {
	try {
		const res = await useClient().get("/resale/all")

		if (res.status >= 400) {
			throw new Error(res.data.message)
		}
		// const config = {
		// 	method: "get",
		// 	url: `${process.env.VUE_APP_BACK}/resale/all`,
		// 	headers: {
		// 		Authorization: `Bearer ${token}`,
		// 	},
		// }

		// const res = await axios(config)

		return { data: res.data.data.result, pageCount: res.data.data.total }
	} catch (error) {
		return error
	}
}

export const getUsers = async (id: string) => {
	try {
		const res = await useAuthClient().get(`/user/${id}/role`)

		if (res.status >= 400) {
			throw new Error(res.data.message)
		}
		// const config = {
		// 	method: "get",
		// 	url: `${process.env.VUE_APP_AUTH}/user/${id}/role`,
		// 	headers: {
		// 		Authorization: `Bearer ${token}`,
		// 	},
		// }

		// const res = await axios(config)

		return { status: "success", data: res }
	} catch (error: any) {
		return { status: "failed", data: error }
	}
}

export const getImages = async (brand: string) => {
	try {
		const res = await useClient().get(`/image?brand=${brand}`)

		if (res.status >= 400) {
			throw new Error(res.data.message)
		}
		// const config = {
		// 	method: "get",
		// 	url: `${process.env.VUE_APP_AUTH}/user/${id}/role`,
		// 	headers: {
		// 		Authorization: `Bearer ${token}`,
		// 	},
		// }

		// const res = await axios(config)

		return { status: "success", data: res }
	} catch (error: any) {
		return { status: "failed", data: error }
	}
}

export const getUser = async (id: string) => {
	try {
		const res = await useAuthClient().get(`/user/${id}`)

		if (res.status >= 400) {
			throw new Error(res.data.message)
		}
		// const config = {
		// 	method: "get",
		// 	url: `${process.env.VUE_APP_AUTH}/user/${id}`,
		// 	headers: {
		// 		Authorization: `Bearer ${token}`,
		// 	},
		// }

		// const res = await axios(config)

		return { status: "success", data: res.data.data }
	} catch (error: any) {
		return { status: "failed", data: error }
	}
}

export const deleteUser = async (id: string) => {
	try {
		const res = await useClient().patch(`/seller/inative/${id}`, {}, {})

		if (res.status >= 400) {
			throw new Error(res.data.message)
		}

		// const config = {
		// 	method: "patch",
		// 	url: `${process.env.VUE_APP_BACK}/seller/inative/${id}`,
		// 	headers: {
		// 		Authorization: `Bearer ${token}`,
		// 	},
		// }

		// const res = await axios(config)
		return { status: "success", data: res.data.data }
	} catch (error: any) {
		return { status: "failed", data: error }
	}
}

export const requestRenovation = async (
	oldReason: string,
	status: string,
	id?: string,
	reason?: string,
	probNew?: string,
	probOld?: string
) => {
	try {
		const userId = JSON.parse(Cookies.get("userid") || "")
		const username = JSON.parse(Cookies.get("username") || "")

		const modifications: Array<IModification> = []

		if (probNew !== probOld) {
			modifications.push({
				attribute: "Situação",
				oldValue:
					probabilityClosing.find((value: any) => probOld === value.key)
						?.value || "N/C",
				newValue:
					probabilityClosing.find((value: any) => probNew === value.key)
						?.value || "N/C",
			})
		}

		if (oldReason !== reason) {
			modifications.push({
				attribute: "Motivo",
				oldValue: oldReason,
				newValue: reason,
			})
		}

		modifications.push({
			attribute: "Status",
			oldValue:
				opportunityStatus.find((value: any) => value.key === status)?.value ||
				"",
			newValue: "Aguardando Renovação",
		})

		const res = await useClient().patch(
			`/opportunity/renovation/${id}/request`,
			{ reason: reason, probabilityClosing: probNew },
			{}
		)

		if (res.status >= 400) {
			throw new Error(res.data.message)
		} else {
			createHistory({
				typeId: id || "",
				username: username,
				userId: userId,
				type: Types.OPPORTUNITY,
				actionType: Types.UPDATE,
				modifications: modifications,
			})
		}

		// const config = {
		// 	method: "patch",
		// 	url: `${process.env.VUE_APP_BACK}/opportunity/renovation/${id}/request`,
		// 	headers: {
		// 		Authorization: `Bearer ${token}`,
		// 	},
		// }

		// const res = await axios(config)
		return { status: "success", data: res.data.data }
	} catch (error: any) {
		return { status: "failed", data: error }
	}
}

export const updateOpportunity = async (
	id?: string,
	data?: ICreateOpportunity,
	oldProbabilityClosing?: string
) => {
	try {
		const userId = JSON.parse(Cookies.get("userid") || "")
		const username = JSON.parse(Cookies.get("username") || "")

		const modifications: Array<IModification> = []

		if (data!.probabilityClosing !== oldProbabilityClosing) {
			modifications.push({
				attribute: "Situação",
				oldValue:
					probabilityClosing.find(
						(value: any) => oldProbabilityClosing === value.key
					)?.value || "N/C",
				newValue:
					probabilityClosing.find(
						(value: any) => data!.probabilityClosing === value.key
					)?.value || "N/C",
			})
		}

		const res = await useClient().patch(
			`/opportunity`,
			{ probabilityClosing: data?.probabilityClosing, id: data?.id },
			{}
		)
		if (res.status === 500) {
			return { status: "failed", data: res.data.message }
		} else {
			createHistory({
				typeId: id || "",
				username: username,
				userId: userId,
				type: Types.OPPORTUNITY,
				actionType: Types.UPDATE,
				modifications: modifications,
			})
		}
		return { status: "success", data: res.data.data }
	} catch (error: any) {
		return { status: "failed", data: error }
	}
}

export const renovation = async (
	id?: string,
	status?: string,
	reason?: string,
	oldReason?: string,
	oldStatus?: string,
	probClosing?: string,
	oldProbabilityClosing?: string,
	name?: string
) => {
	try {
		const userId = JSON.parse(Cookies.get("userid") || "")
		const username = JSON.parse(Cookies.get("username") || "")

		const modifications: Array<IModification> = []

		if (oldReason !== reason) {
			modifications.push({
				attribute: "Motivo",
				oldValue: oldReason,
				newValue: reason,
			})
		}

		if (probClosing !== oldProbabilityClosing) {
			modifications.push({
				attribute: "Situação",
				oldValue:
					probabilityClosing.find(
						(value: any) => oldProbabilityClosing === value.key
					)?.value || "N/C",
				newValue:
					probabilityClosing.find((value: any) => probClosing === value.key)
						?.value || "N/C",
			})
		}

		const newStatus =
			opportunityStatus.find((value: any) => value.key === status)?.value || ""

		modifications.push({
			attribute: "Status",
			oldValue:
				opportunityStatus.find((value: any) => value.key === oldStatus)
					?.value || "",
			newValue: newStatus === "Aprovado" ? "Análise" : newStatus,
		})

		const res = await useClient().patch(
			`/opportunity/renovation/${id}`,
			{
				status: status,
				reason: reason,
				probabilityClosing: probClosing,
				name: name,
			},
			{}
		)
		if (res.status === 500) {
			return { status: "failed", data: res.data.message }
		} else {
			createHistory({
				typeId: id || "",
				username: username,
				userId: userId,
				type: Types.OPPORTUNITY,
				actionType: Types.UPDATE,
				modifications: modifications,
			})
		}
		return { status: "success", data: res.data.data }
	} catch (error: any) {
		return { status: "failed", data: error }
	}
}

export const getCnpj = async (docNum: string) => {
	try {
		const cnpj = Number(docNum.replace(/[^a-zA-Z0-9]/g, ""))

		const res = await useClient().get(`/cnpj/${cnpj}`)

		if (res.status >= 400) {
			throw new Error(res.data.message)
		}

		// const config = {
		// 	method: "get",
		// 	url: `${process.env.VUE_APP_BACK}/cnpj/${cnpj}`,
		// }

		// const res = await axios(config)

		return res
	} catch (error: any) {
		return error.response
	}
}

export const getStates = async () => {
	try {
		const config = {
			method: "get",
			url: `${process.env.VUE_APP_IBGE}/localidades/estados`,
		}

		const res = await axios(config)

		return res.data
	} catch (error: any) {
		return error.response
	}
}

export const getCities = async () => {
	try {
		const config = {
			method: "get",
			url: `${process.env.VUE_APP_IBGE}/localidades/estados/31/municipios`,
		}

		const res = await axios(config)

		return res.data
	} catch (error: any) {
		return error.response
	}
}

export const registerResale = async (resale: IRelase) => {
	try {
		const data = JSON.stringify({
			email: resale.email,
			phone: resale.phone,
			contactName: resale.contact_name,
			cnpj: resale.cnpj,
			role: [],
			brand: resale.brand,
			fantasyName: resale.fantasyName,
			corporateName: resale.corporateName,
			street: resale.street,
			state: resale.state,
			cep: resale.cep,
			city: resale.city,
			country: resale.country,
		})

		const res = await useClient().post(`/register/resale`, data, {
			"Content-Type": "application/json",
		})

		if (res.data && res.data.message === EmailError.EXISTENT_EMAIL) {
			return {
				status: res.status,
				message: res.data.message,
			}
		}

		if (res.status >= 400) {
			return {
				status: res.status,
			}
		}

		// const config = {
		// 	method: "post",
		// 	url: `${process.env.VUE_APP_BACK}/register/resale`,
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 	},
		// 	data: data,
		// }

		// const res = await axios(config)

		return res
	} catch (error) {
		return error
	}
}

export const updateStatusResale = async (
	status: string,
	id: string,
	oldStatus: string,
	reason = ""
): Promise<AxiosResponse | any> => {
	try {
		const url = window.location.href
		const http = window.location.protocol
		const parsedUrl = new URL(url)
		const host = `${http}//${parsedUrl.host}`

		const res = await useClient().patch(
			`/resale/update/${id}`,
			{
				status,
				reason,
				host,
			},
			{
				"Content-Type": "application/json",
			}
		)

		if (res.status >= 400) {
			return {
				status: res.status,
			}
		}

		// const config = {
		// 	method: "patch",
		// 	url: `${process.env.VUE_APP_BACK}/resale/update/${id}`,
		// 	headers: {
		// 		Authorization: `Bearer ${token}`,
		// 		"Content-Type": "application/json",
		// 	},
		// 	data: {
		// 		status,
		// 		reason,
		// 		host,
		// 	},
		// }

		// const res = await axios(config)

		return res
	} catch (error: any) {
		return error.response
	}
}

export const getFilterResale = async (token: string, status: string) => {
	try {
		const res = await useClient().get(`/resale/all?status=${status}`)

		// const config = {
		// 	method: "get",
		// 	url: `${process.env.VUE_APP_BACK}/resale/all?status=${status}`,
		// 	headers: {
		// 		Authorization: `Bearer ${token}`,
		// 	},
		// }

		// const res = await axios(config)

		if (res.status >= 400) {
			throw new Error(res.data.message)
		}

		return res.data.data.result
	} catch (error: any) {
		return error.response
	}
}

export const sendMail = async (
	data: IContact,
	company: string,
	admEmail: string
) => {
	const mailler = new MailRepository()

	const mail = new Mailer()

	const content = mail.createContent(
		company,
		data.description,
		data.fullName,
		data.email
	)
	const message = new Message(content, data.title, [admEmail], company, "html")
	const result = await mailler.sendMessage(message)

	return result
}

export const getUserCompany = async (id: string) => {
	try {
		const res = await useClient().get(`/company/seller/${id}`)
		if (res.status >= 400) {
			return {
				status: res.status,
				data: res.data,
			}
		}

		return res
	} catch (error: any) {
		return error.response
	}
}

export const getResaleById = async (id: string) => {
	try {
		const res = await useClient().get(`/resale/id/${id}`)
		if (res.status >= 400) {
			return {
				status: res.status,
				data: res.data,
			}
		}

		return res
	} catch (error: any) {
		return error.response
	}
}

export const getCompanyById = async (id: string) => {
	try {
		const res = await useClient().get(`/company/${id}`)
		if (res.status >= 400) {
			return {
				status: res.status,
				data: res.data,
			}
		}

		return res
	} catch (error: any) {
		return error.response
	}
}

export const getCompanyByName = async () => {
	try {
		const res = await useClient().get(`/company/name`)
		if (res.status >= 400) {
			return {
				status: res.status,
				data: res.data,
			}
		}

		return res
	} catch (error: any) {
		return error.response
	}
}
