import styled from "vue3-styled-components"

const input = { validationError: Boolean, value: String }
const inputSeller = { validationError: Boolean }

export const Input = styled("textarea", input)`
	background: ${(props) => (props.value ? "#fff" : "#e1e4ed")};
	box-shadow: 0 0.3rem 0.62rem #e0e4ee;
	padding: 1rem;
	border-radius: 0.3rem;
	margin-top: -0.1rem;
	font-weight: 700;
	font-size: 0.9rem
	max-width: 23.875rem;
	overflow: auto;
	width: 100%;
	height: 3.5rem;
	border: ${(props) => (props.validationError ? "2px" : 0)} solid ${(props) =>
	props.validationError ? "#f00 " : "#e1e4ed"}
	min-height: 3.5rem;
	margin-bottom: 1rem;
	::webkit-scrollbar-thumb {
		background-color: #083;
	}
	::placeholder,
	::-webkit-input-placeholder {
		color: #fff;
	}
	:-ms-input-placeholder {
		color: #fff;
	}
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	:focus {
		background: #fff;
		outline: 2px solid var(--defaultColor);
	}

	input {
		-moz-appearance: textfield;
	}
`

export const LoginInput = styled("input", inputSeller)`
	background: #e1e4ed;
	box-shadow: 0 0.3rem 0.62rem #e0e4ee;
	padding: 0 1rem;
	border-radius: 0.3rem;
	margin-top: -0.1rem;
	font-weight: 700;
	font-size: 1rem
	width: 21.875rem;
	border: ${(props) => (props.validationError ? "2px" : 0)} solid ${(props) =>
	props.validationError ? "#f00 " : "#e1e4ed"}
	height: 3.75rem;
	outline-color: var(--defaultColor);
	::placeholder,
	::-webkit-input-placeholder {
		color: #fff;
	}
	:-ms-input-placeholder {
		color: #fff;
	}
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	:focus {
		background: #fff;
		outline: 2px solid var(--defaultColor);
	}

	input {
		-moz-appearance: textfield;
	}
`

export const Selector = styled("select", input)`
	background: ${(props) => (props.value ? "#fff" : "#e1e4ed")};
	box-shadow: 0 0.3rem 0.62rem #e0e4ee;
	padding: 0 1rem;
	border-radius: 0.3rem;
	margin-top: -0.1rem;
	font-weight: 700;
	font-size: 1rem;
	width: 100%;
	max-width: 23.875rem;
	margin-bottom: 1rem;
	border: ${(props) => (props.validationError ? "2px" : 0)} solid ${(props) =>
	props.validationError ? "#f00 " : "#e1e4ed"}
	height: 3.75rem;
	outline-color: #e1e4ed;
	::placeholder,
	::-webkit-input-placeholder {
		color: #fff;
	}
	:-ms-input-placeholder {
		color: #fff;
	}
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	:focus {
		background: #fff;
		outline: 2px solid var(--defaultColor);
	}

	input {
		-moz-appearance: textfield;
	}
`
export const SmallInput = styled("input", input)`
	background: ${(props) => (props.value ? "#fff" : "#e1e4ed")};
	box-shadow: 0 0.3rem 0.62rem #e0e4ee;
	padding: 1rem;
	border-radius: 0.3rem;
	margin-top: -0.1rem;
	font-weight: 700;
	font-size: 1rem
	height: 3.5rem;
	max-width: 23.875rem;
	margin-bottom: 1rem;
	width: 100%;
	border: ${(props) => (props.validationError ? "2px" : 0)} solid ${(props) =>
	props.validationError ? "#f00 " : "#e1e4ed"}
	min-height: 3.5rem;
	outline-color: #e1e4ed;
	::placeholder,
	::-webkit-input-placeholder {
		color: #fff;
	}
	:-ms-input-placeholder {
		color: #fff;
	}
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	:focus {
		background: #fff;
		outline-color: #fff;
	}

	input {
		-moz-appearance: textfield;
	}
`

export const SmallTextArea = styled("textarea", input)`
	background: ${(props) => (props.value ? "#fff" : "#e1e4ed")};
	box-shadow: 0 0.3rem 0.62rem #e0e4ee;
	padding: 1rem;
	border-radius: 0.3rem;
	margin-top: -0.1rem;
	font-weight: 700;
	font-size: 1rem
	height: 3.5rem;
	max-width: 23.875rem;
	margin-bottom: 1rem;
	width: 100%;
	border: ${(props) => (props.validationError ? "2px" : 0)} solid ${(props) =>
	props.validationError ? "#f00 " : "#e1e4ed"}
	min-height: 3.5rem;
	outline-color: #e1e4ed;
	::placeholder,
	::-webkit-input-placeholder {
		color: #fff;
	}
	:-ms-input-placeholder {
		color: #fff;
	}
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	:focus {
		background: #fff;
		outline-color: #fff;
	}

	input {
		-moz-appearance: textfield;
	}
`

export const ContactTextArea = styled("textarea")`
	border: ${(props) => (props.validationError ? "2px" : 0)} solid ${(props) =>
	props.validationError ? "#f00 " : "#e1e4ed"}
	width: 28.9rem;
	height: 6.6rem;
	background: #ffffff;
	border-radius: 0.5rem;
	padding: 1rem;
	resize: none;
	font-style: normal;
	font-weight: 700;
	font-size: 1rem; //14px
`

export const TextArea = styled("textarea", inputSeller)`
	border: ${(props) => (props.validationError ? "2px" : 0)} solid ${(props) =>
	props.validationError ? "#f00 " : "#e1e4ed"}
	width: 23rem;
	height: 6.6rem;
	background: #ffffff;
	border-radius: 0.5rem;
	padding: 1rem;
	margin-bottom: 2rem;
	resize: none;
`

export const InputSeller = styled("input", inputSeller)`
	background: #e1e4ed;
	box-shadow: 0 0.3rem 0.62rem #e0e4ee;
	padding: 0 0.8rem;
	border-radius: 0.3rem;
	font-weight: 700;
	font-size: 1rem
	width: 28.9rem;
	border: ${(props) => (props.validationError ? "2px" : 0)} solid ${(props) =>
	props.validationError ? "#f00 " : "#e1e4ed"}
	height: ${(props) => (props.validationError ? `${60 - 4}px` : "3.75rem")};
	outline-color: #e1e4ed;
	::placeholder,
	::-webkit-input-placeholder {
		color: #fff;
	}
	:-ms-input-placeholder {
		color: #fff;
	}
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	:focus {
		background: #fff;
		outline-color: #fff;
	}

	input {
		-moz-appearance: textfield;
	}
`
export const InputContact = styled("input")`
	background: #e1e4ed;
	box-shadow: 0 0.3rem 0.62rem #e0e4ee;
	padding: 0 0.8rem;
	border-radius: 0.3rem;
	font-weight: 700;
	font-size: 1rem
	width: 28.9rem;
	border: ${(props) => (props.validationError ? "2px" : 0)} solid ${(props) =>
	props.validationError ? "#f00 " : "#e1e4ed"}
	height: ${(props) => (props.validationError ? `${60 - 4}px` : "3rem")};
	outline-color: #e1e4ed;
	::placeholder,
	::-webkit-input-placeholder {
		color: #fff;
	}
	:-ms-input-placeholder {
		color: #fff;
	}
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	:focus {
		background: #fff;
		outline-color: #fff;
	}

	input {
		-moz-appearance: textfield;
	}
`

export const InputConfig = styled("input", inputSeller)`
	background: #e1e4ed;
	box-shadow: 0 0.3rem 0.62rem #e0e4ee;
	padding: 0 0.8rem;
	border-radius: 0.3rem;
	font-weight: 700;
	font-size: 1rem
	width: 28.9rem;
	border: ${(props) => (props.validationError ? "2px" : 0)} solid ${(props) =>
	props.validationError ? "#f00 " : "#e1e4ed"}
	height: ${(props) => (props.validationError ? `${60 - 4}px` : "3.75rem")};
	outline-color: #e1e4ed;
	::placeholder,
	::-webkit-input-placeholder {
		color: #fff;
	}
	:-ms-input-placeholder {
		color: #fff;
	}
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	:focus {
		background: #fff;
		outline-color: #fff;
	}

	input {
		-moz-appearance: textfield;
	}
`
