import {
	DashOpportunities,
	IOpportunityStore,
	IResaleStore,
	opportunityFilter,
	States,
} from "@/utils/interfaces"
import { Store, createStore, useStore as vuexUseStore } from "vuex"
import { sellers } from "./sellers"
import { InjectionKey } from "vue"
import { dashOpportunities } from "./dashOpportunities"
import {
	GET_OPPORTUNITIES,
	GET_RESALES,
	SET_401_ERROR,
	SET_ERROR,
	SET_FILTER_OPPORTUNITY,
	SET_OPPORTUNITY,
	SET_OPPORTUNITY_CREATED_ASC,
	SET_OPPORTUNITY_CREATED_DSC,
	SET_OPPORTUNITY_ESTIMATED_ASC,
	SET_OPPORTUNITY_ESTIMATED_DSC,
	SET_RESALE,
	SET_RESALE_CREATED_ASC,
	SET_RESALE_CREATED_DSC,
} from "./mutationsTypes"
import { dateDifference } from "@/utils/date"
import { StatusOpportunity } from "@/utils/enum"
import { getAllResales } from "@/repositories/resale"
import { getFilteredOpportunities } from "@/repositories/opportunity"
import { AxiosError } from "axios"

export const key: InjectionKey<Store<States>> = Symbol()

export const store = createStore<States>({
	state: {
		sellerDetails: {
			sellers: {} as any,
			isNewOpportunityFormValid: true,
		},
		isNewOpportunityFormValid: true,
		isError: false,
		is401: false,
		opportunitiesTable: {
			opportunities: {} as IOpportunityStore,
			estimatedASC: {} as IOpportunityStore,
			estimatedDSC: {} as IOpportunityStore,
			createdASC: {} as IOpportunityStore,
			createdDSC: {} as IOpportunityStore,
		},
		resalesTable: {
			resales: {} as IResaleStore,
			createdASC: {} as IResaleStore,
			createdDSC: {} as IResaleStore,
		},
		dashOpportunities: {
			dashOpportunities: [],
			approveds: [] as DashOpportunities[],
			allOpportunities: [],
			totalApproved: 0,
			totalDisapproved: 0,
			totalAnalysis: 0,
			approvedMonth: 0,
			disapprovedMonth: 0,
			analysisMonth: 0,
			arrayTotalApproved: [0],
			arrayTotalDisapproved: [0],
		},

		filterOpportunities: {} as opportunityFilter,
	},
	mutations: {
		[SET_OPPORTUNITY](state, opportunities: IOpportunityStore) {
			state.is401 = false
			state.isError = false
			state.opportunitiesTable.opportunities = opportunities
		},
		[SET_OPPORTUNITY_ESTIMATED_ASC](state, opportunities: IOpportunityStore) {
			const data = opportunities.data.slice()
			data.sort(function (a: any, b: any) {
				return Number(a.estimated) - Number(b.estimated)
			})
			state.is401 = false
			state.isError = false
			state.opportunitiesTable.estimatedASC = {
				data,
				quantityPage: opportunities.quantityPage,
			}
		},

		[SET_OPPORTUNITY_ESTIMATED_DSC](state, opportunities: IOpportunityStore) {
			const data = opportunities.data.slice()
			data.sort(function (a: any, b: any) {
				return Number(b.estimated) - Number(a.estimated)
			})
			state.is401 = false
			state.isError = false
			state.opportunitiesTable.estimatedDSC = {
				data,
				quantityPage: opportunities.quantityPage,
			}
		},

		[SET_FILTER_OPPORTUNITY](state, opportunities: opportunityFilter) {
			state.filterOpportunities = opportunities
		},

		[SET_OPPORTUNITY_CREATED_ASC](state, opportunities: IOpportunityStore) {
			const data = opportunities.data.slice()
			data.sort(function (a: any, b: any) {
				const dateA = new Date(a.date)
				const dateB = new Date(b.date)
				return dateA.getTime() - dateB.getTime()
			})
			state.is401 = false
			state.isError = false
			state.opportunitiesTable.createdASC = {
				data,
				quantityPage: opportunities.quantityPage,
			}
		},

		[SET_OPPORTUNITY_CREATED_DSC](state, opportunities: IOpportunityStore) {
			const data = opportunities.data.slice()
			data.sort(function (a: any, b: any) {
				const dateA = new Date(a.date)
				const dateB = new Date(b.date)
				return dateB.getTime() - dateA.getTime()
			})
			state.is401 = false
			state.isError = false
			state.opportunitiesTable.createdDSC = {
				data,
				quantityPage: opportunities.quantityPage,
			}
		},
		[SET_ERROR](state, error: boolean) {
			state.isError = error
		},

		[SET_RESALE](state, resales: IResaleStore) {
			state.resalesTable.resales = resales
		},

		[SET_RESALE_CREATED_ASC](state, resales: IResaleStore) {
			const data = resales.data.slice()
			data.sort(function (a: any, b: any) {
				const dateA = new Date(a.date)
				const dateB = new Date(b.date)
				return dateA.getTime() - dateB.getTime()
			})
			state.resalesTable.createdASC = {
				data,
				quantityPage: resales.quantityPage,
			}
		},

		[SET_RESALE_CREATED_DSC](state, resales: IResaleStore) {
			const data = resales.data.slice()
			data.sort(function (a: any, b: any) {
				const dateA = new Date(a.date)
				const dateB = new Date(b.date)
				return dateB.getTime() - dateA.getTime()
			})
			state.resalesTable.createdDSC = {
				data,
				quantityPage: resales.quantityPage,
			}
		},

		[SET_401_ERROR](state, value) {
			state.is401 = value
		},
	},
	actions: {
		async [GET_OPPORTUNITIES](
			{ commit },
			{ itens_per_page, company, page, filter, date, order }
		) {
			let diffInDays = 0
			await getFilteredOpportunities({
				amount: itens_per_page,
				page: page,
				company,
				date,
				filters: filter,
				order: order,
			}).then((res) => {
				if (res instanceof AxiosError) {
					if (res.response && res.response.status === 401) {
						commit(SET_401_ERROR, true)
					} else {
						commit(SET_ERROR, true)
					}
				} else {
					res.data.map((item) => {
						diffInDays = dateDifference(item.createdAt)
						item.date = item.createdAt
						item.expireDays = String(90 - diffInDays)
						item.createdAt =
							item.status === StatusOpportunity.ANALYSIS
								? String(diffInDays)
								: item.createdAt
					})
					commit(SET_OPPORTUNITY, {
						data: res.data,
						quantityPage: res.quantityPage,
					})
					commit(SET_OPPORTUNITY_ESTIMATED_ASC, {
						data: res.data,
						quantityPage: res.quantityPage,
					})
					commit(SET_OPPORTUNITY_ESTIMATED_DSC, {
						data: res.data,
						quantityPage: res.quantityPage,
					})
					commit(SET_OPPORTUNITY_CREATED_ASC, {
						data: res.data,
						quantityPage: res.quantityPage,
					})
					commit(SET_OPPORTUNITY_CREATED_DSC, {
						data: res.data,
						quantityPage: res.quantityPage,
					})
				}
			})
		},
		async [GET_RESALES](
			{ commit },
			{ itens_per_page, company, page, filter, order }
		) {
			let diffInDays = 0
			await getAllResales({
				amount: itens_per_page,
				page: page,
				filter: filter,
				company,
				order,
			}).then((res) => {
				res.data.map((item: any) => {
					diffInDays = dateDifference(item.createdAt)
					item.date = item.createdAt
					item.createdAt =
						item.status === StatusOpportunity.ANALYSIS
							? String(diffInDays)
							: item.createdAt
				})
				commit(SET_RESALE, {
					data: res.data,
					quantityPage: res.pageCount,
				})
				commit(SET_RESALE_CREATED_ASC, {
					data: res.data,
					quantityPage: res.pageCount,
				})
				commit(SET_RESALE_CREATED_DSC, {
					data: res.data,
					quantityPage: res.pageCount,
				})
			})
		},
	},

	getters: {
		getOpportunitiesTable(state) {
			return {
				data: state.opportunitiesTable.opportunities.data,
				quantityPage: state.opportunitiesTable.opportunities.quantityPage,
			}
		},
		getOpportunitiesCreatedASC(state) {
			return {
				data: state.opportunitiesTable.createdASC.data,
				quantityPage: state.opportunitiesTable.createdASC.quantityPage,
			}
		},
		getOpportunitiesCreatedDSC(state) {
			return {
				data: state.opportunitiesTable.createdDSC.data,
				quantityPage: state.opportunitiesTable.createdDSC.quantityPage,
			}
		},
		getOpportunitiesEstimatesASC(state) {
			return {
				data: state.opportunitiesTable.estimatedASC.data,
				quantityPage: state.opportunitiesTable.estimatedASC.quantityPage,
			}
		},
		getOpportunitiesEstimatesDSC(state) {
			return {
				data: state.opportunitiesTable.estimatedDSC.data,
				quantityPage: state.opportunitiesTable.estimatedDSC.quantityPage,
			}
		},
		getResalesTable(state) {
			return {
				data: state.resalesTable.resales.data,
				quantityPage: state.resalesTable.resales.quantityPage,
			}
		},
		getResalesCreatedASC(state) {
			return {
				data: state.resalesTable.createdASC.data,
				quantityPage: state.resalesTable.createdASC.quantityPage,
			}
		},
		getResalesCreatedDSC(state) {
			return {
				data: state.resalesTable.createdDSC.data,
				quantityPage: state.resalesTable.createdDSC.quantityPage,
			}
		},
		getError(state) {
			return state.isError
		},
		getUserDetails(state) {
			return state
		},
		getDashOpportunities(state) {
			return state.dashOpportunities.dashOpportunities
		},
		getApproveds(state) {
			return state.dashOpportunities.approveds
		},
		getAllOpportunities(state) {
			return state.dashOpportunities.allOpportunities
		},
		getOpportunitiesByStatus(state) {
			return {
				approvedMonth: state.dashOpportunities.approvedMonth,
				disapprovedMonth: state.dashOpportunities.disapprovedMonth,
				analysisMonth: state.dashOpportunities.analysisMonth,
				totalApproved: state.dashOpportunities.totalApproved,
				totalDisapproved: state.dashOpportunities.totalDisapproved,
				totalAnalysis: state.dashOpportunities.totalAnalysis,
			}
		},
		getChartValues(state) {
			return {
				approved: state.dashOpportunities.arrayTotalApproved,
				disapproved: state.dashOpportunities.arrayTotalDisapproved,
			}
		},
		getIsNewOpportunityFormValid(state) {
			return state.isNewOpportunityFormValid
		},
		getError401(state) {
			return state.is401
		},
		getFilteredOpportunities(state) {
			return state.filterOpportunities
		},
	},

	modules: { sellers, dashOpportunities },
})

export function useStore(): Store<States> {
	return vuexUseStore(key)
}
