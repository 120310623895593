export class Credentials {
	private username: string
	private password: string

	constructor(username: string, password: string) {
		this.username = username
		this.password = password
	}

	basicEncoded(): string {
		return btoa(`${this.username}:${this.password}`)
	}
}
