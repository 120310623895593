import { useClient } from "@/clients/AxiosClient"

export const getCnpj = async (docNum: string) => {
	try {
		const cnpj = docNum.replace(/[^a-zA-Z0-9]/g, "")

		const response = await useClient().get(`/cnpj/${cnpj}`)

		if (response.status >= 400) {
			return {
				status: response.status,
				message: response.data.message,
			}
		}

		return response
	} catch (error: any) {
		return error
	}
}
