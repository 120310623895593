import {
	IDashboardOpportunities,
	IOpportunities,
	States,
} from "@/utils/interfaces"
import { Module } from "vuex"
import {
	GET_DASH_OPPORTUNITIES,
	SET_ALL_DASH_OPPORTUNITIES,
	SET_CHART_VALUES,
	SET_DASH_OPPORTUNITIES_APPROVED,
	SET_TOTAL_OPPORTUNITIES,
} from "../mutationsTypes"
import { useClient } from "@/clients/AxiosClient"
import { calculateEstimateValue } from "@/utils/money"

const currentMonth = new Date().getMonth()

export const dashOpportunities: Module<IDashboardOpportunities, States> = {
	mutations: {
		[SET_DASH_OPPORTUNITIES_APPROVED](state, details: IOpportunities) {
			const opportunitiesMonth = details?.opportunities.filter(
				(_, index) => index === currentMonth
			)

			const approvedMonth = opportunitiesMonth.map((value) => {
				const approvedFiltering = value.filter(
					(opportunities: any) => opportunities.status === "approved"
				)

				const approveds = approvedFiltering.map((approveds: any) => {
					const price = calculateEstimateValue(approveds.products)
						.toFixed(2)
						.replace(".", ",")
					return {
						id: approveds.id,
						contact: approveds.contact,
						fantasyName: approveds.fantasyName,
						product: approveds.products,
						price: price,
						status: approveds.status,
					}
				})

				return approveds
			})

			state.approveds = approvedMonth[0]
		},

		[SET_ALL_DASH_OPPORTUNITIES](state, details: IOpportunities) {
			let opportunitiesMonth: Array<[]> = []
			details?.opportunities.map((item) =>
				item.map((value) => opportunitiesMonth.push(value))
			)
			const length = opportunitiesMonth.length
			opportunitiesMonth = opportunitiesMonth.slice(length - 11, length)

			const allOpportunities = opportunitiesMonth.map((opportunities: any) => {
				const price = calculateEstimateValue(opportunities.products)
					.toFixed(2)
					.replace(".", ",")
				return {
					id: opportunities.id,
					contact: opportunities.contact,
					fantasyName: opportunities.fantasyName,
					product: opportunities.products,
					price: price,
					status: opportunities.status,
				}
			})

			state.allOpportunities = allOpportunities
		},

		[SET_TOTAL_OPPORTUNITIES](state, details: IOpportunities) {
			const approvedMonth = Array.from(details.totalApproved).filter(
				(_, index) => index === currentMonth
			)[0]

			const disapprovedMonth = Array.from(details.totalDisapproved).filter(
				(_, index) => index === currentMonth
			)[0]

			const analysisMonth = Array.from(details.totalAnalysis).filter(
				(_, index) => index === currentMonth
			)[0]

			const totalApproved = details.approved
			const totalDisapproved = details.disapproved
			const totalAnalysis = details.analysis

			state.totalApproved = totalApproved
			state.totalDisapproved = totalDisapproved
			state.totalAnalysis = totalAnalysis

			state.approvedMonth = approvedMonth
			state.disapprovedMonth = disapprovedMonth
			state.analysisMonth = analysisMonth
		},

		[SET_CHART_VALUES](state, details: IOpportunities) {
			const arrayTotalApproved = details.totalApproved
			const arrayTotalDisaproved = details.totalDisapproved

			state.arrayTotalApproved = arrayTotalApproved
			state.arrayTotalDisapproved = arrayTotalDisaproved
		},
	},
	actions: {
		async [GET_DASH_OPPORTUNITIES]({ commit }, company: string) {
			await useClient()
				.get(`/dashboard/status?&brand=${company}`)
				.then((res) => {
					commit(SET_TOTAL_OPPORTUNITIES, res.data.data)
					commit(SET_DASH_OPPORTUNITIES_APPROVED, res.data.data)
					commit(SET_ALL_DASH_OPPORTUNITIES, res.data.data)
					commit(SET_CHART_VALUES, res.data.data)
				})
		},
	},
}
