import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import LoginPage from "@/views/Login-page.vue"
import SellerDetails from "@/views/SellerDetails.vue"
import Registration from "@/views/Registration-page.vue"
import Painel from "@/views/Painel.vue"
import Users from "@/components/organisms/Sellers.vue"
import Opportunities from "@/components/organisms/Opportunities.vue"
import Settings from "@/components/organisms/Settings.vue"
import ResaleDetails from "@/components/organisms/ResaleDetails.vue"
import Dashboard from "@/components/organisms/Dashboard.vue"
import ListResaleVue from "@/components/organisms/ListResale.vue"
import { AuthorizationUser } from "@/utils/enum"
import { getExp, hasPermission } from "@/utils/permissions"
import RegisteringSeller from "@/components/organisms/RegisteringSeller.vue"
import NewOpportunity from "@/components/organisms/NewOpportunity.vue"
import OpportunityDetails from "@/components/organisms/OpportunityDetails.vue"
import RecoveryPassword from "@/components/organisms/RecoveryPassword.vue"
import Login from "@/components/organisms/Login.vue"
import ContactPage from "@/components/organisms/ContactPage.vue"
import Cookies from "js-cookie"
import ListHistory from "../components/organisms/ListHistory.vue"

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Login",
		component: LoginPage,
		meta: {
			protected: false,
		},
		children: [
			{
				path: "/",
				name: "LoginComponent",
				component: Login,
			},
			{
				path: "/recovery-password",
				name: "RecoveryPassword",
				component: RecoveryPassword,
			},
		],
	},
	{
		path: "/Registration",
		name: "Registration",
		component: Registration,
		meta: {
			protected: false,
		},
	},
	{
		path: "/Contact",
		name: "Contact",
		component: ContactPage,
		meta: {
			protected: false,
		},
	},
	{
		path: "/*",
		redirect: { name: "Login" },
		name: "NotFound",
	},
	{
		path: "/Painel",
		name: "Painel",
		component: Painel,
		meta: {
			protected: false,
		},
		children: [
			{
				path: "Resales",
				name: "Resales",
				component: ListResaleVue,
				meta: {
					protected: true,
					permissions: [
						AuthorizationUser.REPRESENTATIVE,
						AuthorizationUser.SUPPORT,
					],
				},
			},
			{
				path: "Dashboard",
				name: "Dashboard",
				component: Dashboard,
				meta: {
					protected: true,
					permissions: [
						AuthorizationUser.REPRESENTATIVE,
						AuthorizationUser.SUPPORT,
					],
				},
			},
			{
				path: "Resales/details",
				name: "ResaleDetails",
				component: ResaleDetails,
				meta: {
					protected: true,
					permissions: [
						AuthorizationUser.REPRESENTATIVE,
						AuthorizationUser.SUPPORT,
					],
				},
			},
			{
				path: "Opportunities",
				name: "Opportunities",
				component: Opportunities,
				meta: {
					protected: true,
					permissions: [
						AuthorizationUser.REPRESENTATIVE,
						AuthorizationUser.SUPPORT,
						AuthorizationUser.SELLER,
						AuthorizationUser.RESALE,
					],
				},
			},
			{
				path: "Opportunities/registration",
				name: "NewOpportunity",
				component: NewOpportunity,
				meta: {
					protected: true,
					permissions: [AuthorizationUser.SELLER, AuthorizationUser.RESALE],
				},
			},
			{
				path: "Opportunities/:id",
				name: "OpportunityDetails",
				component: OpportunityDetails,
				props: true,
				meta: {
					protected: true,
					permissions: [
						AuthorizationUser.REPRESENTATIVE,
						AuthorizationUser.SUPPORT,
						AuthorizationUser.SELLER,
						AuthorizationUser.RESALE,
					],
				},
			},
			{
				path: "History/:id",
				name: "ListHistory",
				component: ListHistory,
				props: true,
				meta: {
					protected: true,
					permissions: [
						AuthorizationUser.REPRESENTATIVE,
						AuthorizationUser.SUPPORT,
						AuthorizationUser.SELLER,
						AuthorizationUser.RESALE,
					],
				},
			},
			{
				path: "Settings",
				name: "Settings",
				component: Settings,
				meta: {
					protected: true,
					permissions: [
						AuthorizationUser.REPRESENTATIVE,
						AuthorizationUser.SELLER,
						AuthorizationUser.SUPPORT,
						AuthorizationUser.RESALE,
					],
				},
			},
			{
				path: "Users",
				name: "Users",
				component: Users,
				meta: {
					protected: true,
					permissions: [
						AuthorizationUser.SUPPORT,
						AuthorizationUser.RESALE,
						AuthorizationUser.REPRESENTATIVE,
					],
				},
			},
			{
				path: "Users/registration",
				name: "SellerRecord",
				component: RegisteringSeller,
				meta: {
					protected: true,
					permissions: [AuthorizationUser.RESALE],
				},
			},
			{
				path: "Users/:id",
				name: "SellerDetails",
				component: SellerDetails,
				meta: {
					protected: true,
					permissions: [
						AuthorizationUser.RESALE,
						AuthorizationUser.REPRESENTATIVE,
						AuthorizationUser.SUPPORT,
					],
				},
				props: true,
			},
		],
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

router.beforeEach(async (to, from, next) => {
	const exp = getExp()

	const permissions = to.meta.permissions as string[]

	const token = Cookies.get("token")
	const refresh_token = Cookies.get("refresh_token")
	const company = Cookies.get("company")

	if (to.name !== "Login" && exp < new Date().getTime() / 1000 + 1000) {
		return next({ name: "LoginComponent" })
	}

	if (to.meta.protected) {
		if (!hasPermission(permissions)) {
			return next({ name: "LoginComponent" })
		}
	}

	if (
		from.path === "/" &&
		to.path === "/" &&
		exp >= new Date().getTime() / 1000 + 1000 &&
		token &&
		token !== "" &&
		refresh_token &&
		refresh_token !== "" &&
		company &&
		company !== ""
	) {
		localStorage.setItem("isLogged", "false")
		return next({ name: "Painel" })
	}
	if (to.path === "/Painel") {
		return next({ name: "Opportunities" })
	}

	return next()
})

export default router
