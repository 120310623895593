export function formateDate(data: string) {
	const split = data.split("-")
	let dataformat
	if (split[2].length === 4) {
		dataformat = `${split[2]}-${split[1]}-${split[0]}`
	} else {
		dataformat = data
	}

	return dataformat
}

export function formateConclusionDate(data: string) {
	const split = data.split("/")

	const dataformat = `${split[1]}/${split[0]}/${split[2]}`

	return dataformat
}

export function formateCreatedAt(data: string) {
	const split = data.split("-")

	const dataformat = `${split[2]}-${split[1]}-${split[0]}`

	return dataformat
}
