import styled from "vue3-styled-components"

const container = { bg: String }

export const Container = styled.div`
	display: grid;
	grid-gap: 2rem;
	grid-template-columns: 40% 58%;
	justify-content: center;
	background: #f0f2f7;
	@media (min-width: 1024px) and (max-width: 1278px) {
		grid-template-columns: 45% 55%;
	}
`
export const LoginContainer = styled.div`
	padding: 0 0 2rem 0rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media (min-height: 0px) and (max-height: 700px) {
		height: 100vh;
		padding: 2rem 0 2rem 0rem;
	}

	@media (min-width: 1024px) and (max-width: 1278px) {
		height: 100vh;
	}
`

export const SliderContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@media (min-height: 0px) and (max-height: 700px) {
		height: 100vh;
	}

	@media (min-width: 1024px) and (max-width: 1278px) {
		height: 100%;
	}
`
export const NewOpportunityContainer = styled.div`
	margin: 4rem 4rem;
`

export const NewOpportunityHeader = styled.div`
	display: flex;
	margin: 1.5rem 0;
	justify-content: space-between;
	align-items: center;
`

export const NewOpportunityBody = styled.div`
	display: grid;
	width: 100%;
	margin-bottom: 1.5rem;
	justify-content: space-between;
	align-items: start;
`

export const OpportunityDetailsBody = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 1.5rem;
	justify-content: space-between;
	align-items: start;

	@media (min-width: 1023px) and (max-width: 1279px) {
		flex-direction: column;
		gap: 1rem 0;
	}
`

export const NewOpportunityBodyGeneral = styled.div`
	display: grid;
	grid-template-columns: 40% 60%
	margin-bottom: 1.5rem;
	justify-content: space-between;
	align-items: start;
	@media (min-width: 1023px) and (max-width: 1279px) {
		grid-template-columns: 100%;
		grid-template-rows: 56px 90%;
		grid-gap: 1rem;
	}
`

export const NewOpportunityRowInput = styled.div`
	display: grid;
	grid-template-columns: 50% 50%;
	margin-bottom: 1.5rem;
	width: 100%;
	max-width: 800px;
	grid-gap: 1rem;
`

export const NewOpportunityProjectBody = styled.div`
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 50% 50%;
	width: 100%;
	max-width: 800px;
	margin-bottom: 1.5rem;
`

export const InputRecoveryContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
	color: #b5bfc9;
`
export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	grid-gap: 0.5rem;
	justify-content: space-evenly;
	height: 17rem;
	align-items: flex-start;
	color: #b5bfc9;
`

export const ButttonContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 11rem;
	margin-bottom: 1.5rem;
	@media (min-width: 0px) and (max-width: 1400px) {
		margin-bottom: 0rem;
	}
	color: #fff;
`

export const IconContainer = styled.div`
	position: relative;
	color: #a4a4a4;
	top: -2.6rem;
	left: 19rem;
	@media (min-width: 0px) and (max-width: 767px) {
		left: -8.5rem;
	}
`

export const SelectorIconContainer = styled.div`
	position: relative;
	color: #a4a4a4;
	top: -2.6rem;
	left: 19.5rem;
	@media (min-width: 0px) and (max-width: 767px) {
		left: -8.5rem;
	}
`
export const ContactContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
`

export const RegistrationContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
`
export const RecordGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 2rem;
	@media (min-width: 1500px) {
		width: 80%;
		margin: 0 auto;
	}
	padding-bottom: 1rem;
	margin-top: 1.25rem;
	color: #a4a4a4;
`

export const RecordRegister = styled.div`
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 2rem;
	padding-bottom: 1rem;
	margin-top: 1.25rem;
	color: #a4a4a4;
`
export const RecordContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	grid-gap: 0.5rem;
	height: auto;
`

export const ModalContainer = styled.div`
	width: 100vw;
	height: 100vh;
	background: rgba(2, 30, 71, 0.1);
	backdrop-filter: blur(15px);
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
`
export const WrapperContainer = styled.div`
	width: 19.93rem;
	height: 16.75rem;
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
	background: #f8f8f8;
	color: #000;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	position: relative;
	padding: 1rem;
	z-index: 10;
	border-radius: 10px;
	font-weight: 400;
	font-size: 20px;
`
export const ReproachWrapper = styled.div`
	width: 26rem;
	height: 25rem;
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
	background: #f8f8f8;
	color: #000;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	position: relative;
	z-index: 10;
	border-radius: 10px;
	font-weight: 400;
	font-size: 20px;
`

export const IconDiv = styled.div`
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 0.93rem;
	display: flex;
	align-items: center;
	justify-content: center;
`
export const IconContainerBorder = styled.div`
	border-width: 0.12rem;
	width: 2rem;
	height: 2rem;
	border-radius: 0.62rem;
	display: flex;
	align-items: center;
	justify-content: center;
`
export const LoadingContainer = styled.div`
	width: 100vw;
	height: 100vh;
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	position: relative;
	z-index: 10;
	font-weight: bold;
`
export const ModalLoading = styled.div`
	width: 100vw;
	height: 100vh;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	background: rgba(0, 0, 0, 0.5);
`
export const PainelContainer = styled.div`
	display: grid;
	grid-template-columns: 5% 95%;
	background: #f0f2f7;
`
export const MenuContainer = styled.div`
	width: 5.6rem;
	height: 100%;
	min-height: 100vh;
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	color: #b5bfc9;
	z-index: 2
	padding: 1rem 0 5rem 0rem;
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
`
export const MenuIconsContainer = styled.div`
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`
export const ResaleListContainer = styled.div`
	display: flex;
	flex-direction: column
	padding: 2rem 4rem;
	padding-top: 3.75rem;
	height: 100vh;
	width: 100%;
	@media (min-width: 1024px) and (max-width: 1700px) {
		padding-top: 1rem;
	}
`
export const HeaderContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 10%);
	column-gap: 1rem;
	@media (min-width: 1023px) and (max-width: 1279px) {
		te-columns: repeat(5, 20%);
	}
	@media (min-height: 0px) and (max-height: 700px) {
		grid-template-columns: repeat(5, 15%);
	}
	@media (min-width: 1279px) and (max-width: 1700px) {
		grid-template-columns: repeat(5, 15%);
	}
`

export const HeaderSellerContainer = styled.div`
	display: grid;

	grid-template-columns: 40% calc(60% - 1rem);
	column-gap: 1rem;
	@media (min-width: 1024px) and (max-width: 1279px) {
		grid-template-columns: 40% calc(60% - 1rem);
	}
	@media (min-height: 0px) and (max-height: 700px) {
		grid-template-columns: 40% calc(60% - 1rem);
	}
`

export const HeaderOpportunityContainer = styled.div`
	display: grid;
	grid-template-columns: 60% calc(40%);
	column-gap: 1rem;
	@media (min-width: 1024px) and (max-width: 1279px) {
		grid-template-columns: 60% calc(40%);
	}
	@media (min-height: 0px) and (max-height: 700px) {
		grid-template-columns: 85% calc(15%);
	}
`

export const FilterContainer = styled.div`
	height: 2.5rem;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	border-radius: 0.5rem;
	padding: 1rem;
	cursor: pointer;
`

export const OpportunitySectionContainer = styled.div`
	height: 2.5rem;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	border-radius: 0.5rem;
	padding: 1rem;
`

export const ScreenContainer = styled.div`
	height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;

	::-webkit-scrollbar {
		display: none;
	}
`

export const TableContainer = styled.div`
	background: #fff;
	padding: 1.5rem 2rem;
	margin-top: 2.5rem;
	height: auto;
	border-radius: 0.5rem;
	@media (min-width: 1024px) and (max-width: 1700px) {
		margin-top: 1rem;
		padding: 1.5rem 1rem;
	}
	@media (min-height: 0px) and (max-height: 640px) {
		padding: 2rem 1rem;
	}
`
export const HeaderTableContainer = styled.div`
	display: grid;
	grid-template-columns: 16% 15% 16.5% 20% 20% 18% 15% 15%;
	padding: 2rem 5rem;
	font-size: 0.75rem;
	@media (min-width: 1400px) and (max-width: 1600px) {
		grid-template-columns: 16% 15% 17% 16% 20% 18% 15% 3%;
	}
	@media (min-width: 1024px) and (max-width: 1400px) {
		grid-template-columns: 8% 11% 13.5% 15% 15% 18% 15% 15%;
	}
	@media (min-height: 0px) and (max-height: 700px) {
		grid-template-columns: 16% 16% 17% 16% 20% 14% 12%;
		padding: 1.5rem 5rem;
		margin-bottom: -1rem;
	}
`
export const SellersTableHeaderContainer = styled.div`
	display: grid;
	grid-template-columns: 22% 26% 30%;
	padding: 2rem 5rem;
	grid-gap: 1rem;
	font-size: 0.75rem;
	@media (min-width: 1024px) and (max-width: 1700px) {
		grid-template-columns: 20% 25% 30%;
	}
	@media (min-height: 0px) and (max-height: 700px) {
		grid-template-columns: 18% 30% 37%;
		margin-bottom: -1rem;
	}
`

export const OpportunitiesTableHeaderContainer = styled.div`
	display: grid;
	grid-template-columns: 13% 14% 7% 17% 17% 18% 18% 3%;

	padding: 2rem 3rem;
	font-size: 0.75rem;
	@media (min-width: 1024px) and (max-width: 1700px) {
		grid-template-columns: 11% 15% 6% 19% 19% 17% 15% 3%;
	}
	@media (max-width: 1024px) {
		grid-template-columns: 13% 15% 5% 18% 18% 18% 18% 3%;
		grid-gap: 1%;
	}
	@media (min-height: 0px) and (max-height: 700px) {
		grid-template-columns: 13% 15% 5% 18% 18% 18% 18% 3%;
		margin-bottom: -1rem;
	}
`

export const RowContainer = styled.div`
	display: grid;
	grid-gap: 1rem
	grid-template-columns: 14% 15% 15.5% 18% 18% 13% 15% 15%;
	padding: 2rem 5rem;
	font-size: 0.75rem;
	align-items: center;

	@media (min-width: 1024px) and (max-width: 1600px) {
		grid-template-columns: 12% 15% 16.5% 15% 17% 14% 18% 8%;
	}
	@media (min-width: 1601px) and (max-width: 1700px) {
		grid-template-columns: 13% 15% 15.5% 19% 18% 12% 15% 15%;
	}
	@media (min-height: 0px) and (max-height: 700px) {
		grid-template-columns: 12% 16% 16.5% 13% 18% 14% 14% 13%;
	}
`
export const RowSellerContainer = styled.div`
	display: grid;
	grid-template-columns: 21% 27% 50% 60% 6%;
	padding: 2rem 5rem
	grid-gap: 1rem;
	font-size: 0.75rem;
	align-items: center;

	@media (min-width: 1024px) and (max-width: 1700px) {
		grid-template-columns: 19% 26% 51% 50% 10%;
	}
	@media (min-height: 0px) and (max-height: 700px) {
		grid-template-columns: 17% 30% 45% 39% 10%;
	}
`

export const OverflowRowContainer = styled.div`
	overflow-y: auto;
	overflow-x: hidden;
	height: auto;
	max-height: 40rem;
	@media (min-width: 1024px) and (max-width: 1700px) {
		height: auto;
	}
	@media (min-height: 701px) and (max-height: 800px) {
		height: 28rem;
	}
	@media (min-height: 650px) and (max-height: 700px) {
		height: 22rem;
	}
	@media (min-height: 0px) and (max-height: 650px) {
		height: 20rem;
	}
	::-webkit-scrollbar {
		display: none;
	}
`

export const RowOpportunitiesContainer = styled.div`
	display: grid;
	grid-template-columns: 13% 14% 13% 11% 15% 16% 10% 2%;
	padding: 2rem 3rem;
	grid-gap: 0.5rem
	font-size: 0.75rem;
	align-items: center;
	@media (min-width: 1400px) and (max-width: 1600px) {
		grid-template-columns: 13% 15% 12% 14% 14% 13% 10% 2%;
	}

	@media (min-width: 1601px) and (max-width: 1700px) {
		grid-template-columns: 11% 15% 13% 13% 15% 16% 11% 2%;
	}
	@media (min-width: 1024px) and (max-width: 1400px) {
		grid-template-columns: 13% 15% 11% 15% 13% 13% 10% 2%;
	}
	@media (max-width: 1024px) {
		grid-template-columns: 17% 14% 11% 8% 11% 17% 17% 2%;
	}
	@media (min-height: 0px) and (max-height: 700px) {
		grid-template-columns: 12% 15% 12% 12% 14% 16% 10% 2%;
	}
`

export const PaginationContainer = styled.div`
	display: grid;
	grid-template-columns: 4% 3% auto 3% 2%;
	justify-content: space-evenly;
	padding-right: 1rem;
	align-items: center;
	color: var(--defaultColor);
	width: 33%;
`
export const ApprovedContainer = styled.div`
	margin: 0 auto;
	width: 5.5rem;
	height: 1.87rem;
	background: #20dc3333;
	color: #15d177;
	border-radius: 0.25rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	font-size: 0.75rem;
`
export const WaitingContainer = styled.div`
	margin: 0 auto;
	width: 5.5rem;
	height: 2.5rem;
	background: #ff8f3333;
	color: #ff7a00;
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
	font-weight: 500;
	border-radius: 0.25rem;
	font-size: 0.75rem;
`

export const ExpiredContainer = styled.div`
	margin: 0 auto;
	width: 5.5rem;
	height: 2rem;
	background: #e0e0e0;
	color: #424242;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	border-radius: 0.25rem;
	font-size: 0.75rem;
`

export const AnalysisContainer = styled.div`
	margin: 0 auto;
	width: 9.875rem;
	height: 2rem;
	background: #fbd0371a;
	color: #e88c02;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	border-radius: 0.25rem;
	font-size: 0.75rem;
`

export const DisapprovedContainers = styled.div`
	margin: 0 auto;
	width: 5.5rem;
	height: 2rem;
	background: #ff574d1a;
	color: #fb3f61;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	border-radius: 0.25rem;
	font-size: 0.75rem;
`
export const RenovationContainer = styled.div`
	margin: 0 auto;
	width: 5.5rem;
	height: 2rem;
	background: #03a1db1a;
	color: #00aeef;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	border-radius: 0.25rem;
	font-size: 0.75rem;
`
export const ApproveResaleContainer = styled.div`
	padding: 1rem 4rem;
	display: flex;
	flex-direction: column;
	height: 100vh;
	grid-gap: 1rem;
	@media (min-width: 1024px) and (max-width: 1700px) {
		padding: 2rem 4rem;
	}
`
export const ItemsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	@media (min-width: 1024px) and (max-width: 1700px) {
		width: 100%;
	}
`
export const LoadingTableContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	position: relative;
	z-index: 10;
	font-weight: bold;
`
export const TableLoading = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	color: #fff;
	background: rgba(0, 0, 0, 0.1);
`
export const GraphicContainer = styled.div`
	background: #fff;
	border-radius: 0.5rem;
	padding: 1rem 1rem;
	position: relative;
	max-width: calc(100vw - 60%);

	width: 100% !important;
	height: 100%;
	@media (min-width: 1601px) {
		padding: 1rem 1.5rem;
	}
`
export const DashboardContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 35%;
	padding: 2rem 4rem;
	grid-column-gap: 1rem;
	margin-left: 2rem;
	@media (min-width: 1024px) and (max-width: 1300px) {
		grid-template-columns: 1fr 30%;
	}

	@media (min-width: 1301px) and (max-width: 1600px) {
		grid-template-columns: 1fr 28%;
	}

	@media (min-height: 0px) and (max-height: 700px) {
		margin-left: 0rem;
	}
`
export const DashboardColumnOne = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 35% 1fr;
	grid-row-gap: 1rem;

	@media (min-width: 1024px) and (max-width: 1600px) {
		grid-template-rows: 40% 1fr;
	}
`
export const DashboardColumnTwo = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(3, 15%) 1fr;
	grid-row-gap: 1rem;
`

export const DashboardWrapper = styled.div`
	width: auto;
	background: #fff;
	border-radius: 0.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1.5rem;
`
export const ToolContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 10% repeat(4, minmax(0, 20%));
	grid-row-gap: 1rem;
	background: #fff;
	border-radius: 0.5rem;
	padding: 1rem 1.5rem;
`
export const IconTools = styled.div`
	width: 2rem;
	height: 2rem;
	border-radius: 0.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (min-width: 1400px) {
		width: 4rem;
		height: 4rem;
	}
`
export const ToolsGrid = styled.div`
	display: grid;
	grid-template-columns: 15% 85%;
	align-items: center;
`
export const ToolsTextGrid = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-evenly;
	padding-left: 1.25rem;
`
export const IconsTools = styled("div", container)`
	width: 3rem;
	height: 3rem;
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ bg }) => bg as string};
	@media (min-width: 1023px) and (max-width: 1279px) {
		width: 2rem;
		height: 2rem;
	}
`

export const DashboardFilterContainer = styled.div`
	display: grid;
	grid-template-columns: 30% 59%;
	column-gap: 1rem;
	margin-top: 1rem;
	@media (min-width: 1023px) and (max-width: 1279px) {
		grid-template-columns: 40% 58%;
	}
	@media (min-height: 0px) and (max-height: 780px) {
		grid-template-columns: 40% 59%;
	}
	@media (min-width: 1400px) {
		grid-template-columns: 32% 66%;
	}
`
export const DashboardTable = styled.div`
	width: auto;
	background: #fff;
	border-radius: 0.5rem;
	padding: 2rem 1.5rem 0 1.5rem;
	@media (min-width: 1400px) {
		padding: 2rem 2rem;
	}
`
export const HeaderDashboardContainer = styled.div`
	display: grid;
	grid-template-columns: 18% 25% 15% 23% 15%;
	padding: 2rem 0;
	font-size: 0.75rem;
	color: #b5bfc9;
	@media (min-width: 1400px) {
		grid-template-columns: 18% 21% 19% 21% 15%;
	}
`
export const DashboardTableRow = styled.div`
	display: grid;
	grid-template-columns: 18% 22% 22% 16% 16% 5%;
	padding: 1.5rem 0;
	font-size: 0.75rem;
	align-items: center;
	color: #1e3147;
	word-break: break-all;
	font-weight: normal;
	height: 2rem;

	@media (min-width: 1400px) {
		height: 4rem;
	}
`
export const OverflowContainer = styled.div`
	height: 12rem;
	overflow-y: auto;
	overflow-x: auto;
	::-webkit-scrollbar {
		display: none;
	}
	@media (min-height: 0px) and (max-height: 700px) {
		height: 10rem;
	}
	@media (min-height: 1024px) and (max-height: 1600px) {
		height: 13rem;
		margin-top: 0.5rem;
	}
	@media (min-width: 1601px) {
		height: 22rem;
		margin-top: 0.5rem;
	}
`

export const OverflowOpportunitiesContainer = styled.div`
	height: 22rem;
	overflow-y: auto;
	overflow-x: auto;
	::-webkit-scrollbar {
		display: none;
	}
	@media (min-height: 0px) and (max-height: 700px) {
		height: 10rem;
	}
	@media (min-width: 1400px) {
		height: 15rem;
		margin-top: 0.5rem;
	}
`
export const OverflowResaleContainer = styled.div`
	height: 29rem;
	overflow-y: auto;
	overflow-x: auto;
	::-webkit-scrollbar {
		display: none;
	}
	@media (min-height: 0px) and (max-height: 700px) {
		height: 18rem;
	}
	@media (min-width: 1023px) and (max-width: 1370px) {
		height: 22rem;
	}
	@media (min-width: 1371px) and (max-width: 1700px) {
		height: 23rem;
		margin-top: 0.5rem;
	}
`
