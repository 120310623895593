import FormData from "form-data"
import { BrandsMap } from "../utils/maps"
import { useMailClient } from "./AxiosClient"
import { ArrayCompanies } from "@/utils/enum"

export abstract class MessageRepository {
	abstract sendMessage(message: Message): void
}
export class MailRepository implements MessageRepository {
	async sendMessage(message: Message) {
		const mailer = new Mailer()
		const result = await mailer.sendEmail(
			message.message,
			message.subject,
			message.to,
			message.company,
			message.contentType
		)
		return result
	}
}

export class Message {
	message: string
	subject: string
	to: Array<string>
	company: string
	contentType?: string

	constructor(
		message: string,
		subject: string,
		to: Array<string>,
		company: string,
		contentType?: string
	) {
		this.message = message
		this.subject = subject
		this.to = to
		this.company = company
		this.contentType = contentType
	}
}

export class Mailer {
	public async sendEmail(
		content: string,
		subject: string,
		to: Array<string>,
		company: string,
		contentType?: string
	) {
		try {
			const email = new FormData()
			email.append("subject", subject)
			email.append("content", content)
			email.append("to", to.join(","))
			email.append("domain", company)
			email.append("contentType", contentType)

			const result = await useMailClient().post(`/mailer/outlook`, email, {})
			if (result.status >= 400) {
				return {
					status: result.status,
					message: "Falha ao enviar o email",
					data: { result, to },
				}
			} else {
				return {
					status: result.status,
					message: "Email enviado com sucesso",
				}
			}
		} catch (err: any) {
			console.log({
				status: err.status,
				message: "Falha ao enviar o email",
			})
		}
	}

	public createContent(
		brand: string,
		message: string,
		name: string,
		email: string
	) {
		const data = BrandsMap[brand]

		const url = window.location.href
		const http = window.location.protocol
		const parsedUrl = new URL(url)
		brand =
			ArrayCompanies.find(
				(value: any) => value.key.toLowerCase() === brand.toLowerCase()
			)?.value || ""

		const image = `${http}//${parsedUrl.host}${data.logo}`
		return `<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width" />
    <title>Boas Vindas</title>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="" />
    <link
      href="https://fonts.googleapis.com/css2?family=Roboto&amp;display=swap"
      rel="stylesheet"
    />
    <style>
      .d-text {
        font-size: 1.25rem;
      }
    </style>
  </head>

  <body>
    <table
      cellspacing="0"
      style="background-color: #e5e5e5; width: 100%; padding: 10px"
    >
      <tbody>
        <tr>
          <td>
            <center>
              <table
                cellspacing="0"
                style="
                  margin: 0px auto;
                  max-width: 600px;
                  border: 2px solid #${data.color};
                  border-radius: 10px;
                  background-color: #fff;
                  width: 100%;
                "
              >
                <tbody>
                  <tr>
                    <td
                      style="
                        align-items: center;
                        justify-content: center;
                        background-color: #${data.color};
                        width: 100%;
                        height: 20%;
                        padding: 1rem 0 1rem 0;
                        border-radius: 10px 10px 0px 0px;
                      "
                    >
                      <table
                        cellspacing="0"
                        style="width: 100%; background-color: #${data.color}"
                      >
                        <tbody>
                          <tr>
                            <td>
                              <table
                                cellspacing="0"
                                style="width: 100%; padding: 10px"
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      <center>
                                        <img
                                          data-imagetype="External"
                                          src="${image}"
                                          alt="${data.alt}"
                                          style="
                                            width: 25% !important;
                                            height: 50% !important; /* padding:1rem; */
                                            margin: 0 auto;
                                          "
                                        />
                                      </center>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 20%; /* padding:1rem 0 1rem 0; */
                      "
                    >
                      <table cellspacing="0" style="width: 100%; padding: 10px">
                        <tbody>
                          <tr>
                            <td style="text-align: center">
                              <table
                                cellspacing="0"
                                style="width: 100%; padding: 10px"
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      <h1
                                        style="
                                          text-align: center;
                                          font-size: 2rem;
                                          font-family: 'Roboto', sans-serif;
                                          margin: 0; /* padding:1rem 0; */
                                        "
                                      >
                                        ${brand}
                                      </h1>
                                      <hr
                                        style="
                                          text-align: center;
                                          padding: 2px;
                                          background-color: #${data.color};
                                          border: none;
                                          width: 50%;
                                          border-radius: 10px;
                                          margin: 0 auto;
                                        "
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 20%; /* padding:1rem 0 1rem 0; */
                      "
                    >
                      <table
                        cellspacing="0"
                        style="width: 100%; /* padding:0 2rem; */"
                      >
                        <tbody>
                          <tr>
                            <td style="text-align: center">
                              <table
                                cellspacing="0"
                                style="width: 100%; padding: 0 0 10px 0"
                              >
                                <tbody>
                                  <tr>
                                    <td style="width: 15%"></td>
                                    <td style="width: 70%">
                                      <table
                                        cellspacing="0"
                                        style="width: 100%; padding: 0 0 10px 0"
                                      >
                                        <tbody>
                                          <tr>
                                            <td>
                                              <p
                                                style="
                                                  text-align: left;
                                                  font-size: 1rem;
                                                "
                                              >
                                                <b>De:</b> ${name}
                                              </p>

                                              <p
                                                style="
                                                  text-align: left;
                                                  font-size: 1rem;
                                                "
                                              >
                                                <b>Email:</b> ${email}
                                              </p>

                                              <p
                                                style="
                                                  text-align: justify;
                                                  font-size: 1rem;
                                                "
                                              >
                                                <b>Descrição:</b> ${message}
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td style="width: 15%"></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        align-items: center;
                        justify-content: center;
                        background-color: #${data.color};
                        width: 100%;
                        height: 20%; /* padding:1rem 0 1rem 0; */
                        border-radius: 0px 0px 10px 10px;
                      "
                    >
                      <table
                        cellspacing="0"
                        style="width: 100%; background-color: #${data.color}"
                      >
                        <tbody>
                          <tr>
                            <td style="text-align: center">
                              <table cellspacing="0" style="width: 100%">
                                <tbody
                                  style="
                                    margin: 0px;
                                    width: 100%;
                                    height: 100%; /* padding: 10px; */
                                  "
                                >
                                  <tr>
                                    <td style="">
                                      <p
                                        style="
                                          font-size: 0.8rem;
                                          text-align: center;
                                          color: #fff;
                                          font-family: 'Roboto', sans-serif;
                                        "
                                      >
                                        Esta é uma mensagem automática. Por favor, não responda!
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </center>
          </td>
        </tr>
      </tbody>
    </table>
  </body>
</html>

`
	}
}
