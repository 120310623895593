import { useClient } from "@/clients/AxiosClient"
import { IGetAllResaleParams } from "@/utils/interfaces"

export async function getAllResales({
	amount,
	page,
	filter,
	company,
	order,
}: IGetAllResaleParams) {
	const response = await useClient().get(
		`resale/all?amount=${amount}&page=${page}&brand=${company}&createdAt=${order}&status=${filter?.status}&date=${filter?.date}`
	)

	if (response.status >= 400) {
		throw new Error(response.data.message)
	}

	return {
		data: response.data.data.result,
		pageCount: Math.ceil(response.data.data.total / amount),
	}
}
