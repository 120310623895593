import { useClient } from "@/clients/AxiosClient"
import {
	IGetSeller,
	IGetSellerResponse,
	INewSeller,
	ISellerInfo,
} from "@/utils/interfaces"
import Cookies from "js-cookie"
import { ref } from "vue"

export const getAllSellers = async ({
	amount,
	page,
	filter,
	brand,
}: IGetSeller): Promise<IGetSellerResponse> => {
	const allSellers = ref<ISellerInfo[]>([] as ISellerInfo[])
	const quantityPage = ref(1)
	let response: any
	const userData = Cookies.get("user_permissions")
	let base: string
	let isRepresentative = false
	let total: number

	if (!userData) {
		throw new Error("User permission not found")
	}

	const userDataArray = Array.from(JSON.parse(userData))

	enum ApiRequest {
		RESALE = `/seller/all`,
		REPRESENTATIVE = `/representative/all`,
	}

	if (userDataArray.includes("RESALE")) {
		base = ApiRequest.RESALE
	} else if (
		userDataArray.includes("REPRESENTATIVE") ||
		userDataArray.includes("SUPPORT")
	) {
		base = ApiRequest.REPRESENTATIVE
		isRepresentative = true
	} else {
		throw new Error("Permission denied")
	}

	if (!filter) {
		response = await useClient().get(
			`${base}?amount=${amount}&page=${page}&status=true${
				isRepresentative ? `&brand=${brand}` : ""
			}`
		)
		total = response.data.data.total
		allSellers.value = response.data.data.result
	} else {
		response = await useClient().get(
			`/seller/name?amount=${amount}&page=${page}&name=${filter}&brand=${brand}`
		)
		total = response.data.data.total

		allSellers.value = response.data.data.data
	}

	if (response.status >= 400) {
		throw new Error(response.data.message)
	}

	quantityPage.value = Math.ceil(total / amount)

	return {
		allSellers: allSellers.value,
		quantityPage: quantityPage.value,
	}
}

export const newSeller = async (newSellerData: INewSeller) => {
	try {
		const response = await useClient().post(
			"/seller",
			{
				...newSellerData,
			},
			{}
		)

		return response
	} catch (error) {
		return error
	}
}
