import { useAuthClient } from "@/clients/AxiosClient"

export async function recoveryPassword(email: string, brand: string) {
	const url = window.location.href
	const http = window.location.protocol
	const parsedUrl = new URL(url)
	const host = `${http}//${parsedUrl.host}`
	try {
		const response = await useAuthClient().post(
			"/auth/reset_password",
			{
				company: brand.toLowerCase(),
				redirect_url: `${host}/`,
				email,
			},
			{}
		)
		return response
	} catch (error) {
		return error
	}
}

export async function updateUserPass(password: string) {
	try {
		const response = await useAuthClient().post(
			"/auth/update_password",
			{
				password,
			},
			{}
		)

		return response
	} catch (error) {
		return error
	}
}
