import { Month } from "@/utils/interfaces"
import { formateDate } from "./functions"

const currentDate = (option: keyof Month) => {
	const month: Month = {
		0: "jan",
		1: "fev",
		2: "mar",
		3: "abr",
		4: "maio",
		5: "jun",
		6: "jul",
		7: "ago",
		8: "set",
		9: "out",
		10: "nov",
		11: "dez",
	}
	return month[option]
}

export const dateDifference = (createdAt: any) => {
	const currentDate = new Date().toISOString().split("T")[0]

	const registeredDate = formateDate(createdAt.split("T")[0])

	const diffInMs =
		new Date(registeredDate).valueOf() - new Date(currentDate).valueOf()

	let diffInDays = Math.abs(diffInMs / (1000 * 60 * 60 * 24))

	if (diffInDays > 60) {
		diffInDays = 60
	}

	return diffInDays
}

export const formatDATE = (dates: string) => {
	const date = dates.split("T")[0]
	const day = date.split("-")[2]
	const year = date.split("-")[0]

	const month = currentDate((Number(date.split("-")[1]) - 1) as keyof Month)

	return `${day} de ${month} de ${year}`
}

export function getDateFormatter(days?: number): Array<string> {
	const date = new Date()
	let newDate: Array<string>

	if (days) {
		date.setDate(date.getDate() + days)
		newDate = new Date(date).toISOString().split("T")
	} else {
		newDate = new Date().toISOString().split("T")
	}
	return newDate
}
