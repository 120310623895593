import {
	setIsAuthenticated,
	setIsAuthenticatedButNotKeepConnect,
	setPermission,
} from "@/utils/permissions"
import { useClient, useAuthClient } from "@/clients/AxiosClient"
import { IUser } from "@/utils/interfaces"
import { Credentials } from "@/clients/credential"
import Cookies from "js-cookie"

export const signin = async (
	user: IUser,
	company: string,
	keepMeConnected: boolean
) => {
	try {
		const credentials = new Credentials(
			user.username.trim(),
			user.password.trim()
		)
		const config = {
			authorization: `Basic ${credentials.basicEncoded()}`,
		}

		const res = await useClient().get(`/login?&brand=${company}`, config)

		if (res.status >= 400) {
			return res
		}

		const token = res.data.data.data.access_token
		const refresh_token = res.data.data.data.refresh_token

		useAuthClient().setAuthorizationToken(`Bearer ${token}`)
		//setBearerAuthorization(useAuthClient(), token)
		const payload: any = await getPayload()
		let rol: any = await getRole(payload.data.data.payload.id)
		rol = rol.data.data.roles.filter(
			(value: any) =>
				value.data.brand === company ||
				value.data.brand === "all" ||
				!value.data.brand
		)
		const roles = []
		for (const data of rol) {
			roles.push(data.name)
		}
		const exp = payload.data.data.payload.exp

		const cookieNameKeepConnection = "keep_connection"
		const cookieValueKeepConnection = `${keepMeConnected}`
		const expiresKeepConnection = 7

		Cookies.set(cookieNameKeepConnection, cookieValueKeepConnection, {
			expires: expiresKeepConnection,
		})

		if (keepMeConnected) {
			setIsAuthenticated(
				token,
				exp,
				refresh_token,
				company,
				payload.data.data.payload.id,
				payload.data.data.payload.name
			)
		} else {
			setIsAuthenticatedButNotKeepConnect(
				token,
				exp,
				company,
				payload.data.data.payload.id,
				payload.data.data.payload.name
			)
		}

		setPermission(roles)

		useClient().setAuthorizationToken(`Bearer ${token}`)
		useClient().setCompany(company)
		//setBearerAuthorization(useClient(), token)
		return res
	} catch (error) {
		return error
	}
}

export const getPayload = async () => {
	try {
		const res = await useAuthClient().get("/auth/payload")

		return res
	} catch (error) {
		return error
	}
}

export const getRole = async (id: string) => {
	try {
		const res = await useAuthClient().get(`/user/${id}/role`)

		return res
	} catch (error) {
		return error
	}
}
