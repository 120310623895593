import { useClient } from "@/clients/AxiosClient"
import { HistoryRoute } from "@/utils/enum"
import { IHistory } from "@/utils/interfaces"
import { ref } from "vue"

export async function createHistory(data: IHistory) {
	try {
		const response = await useClient().post(
			HistoryRoute.CREATE_HISTORY,
			data,
			{}
		)
		if (response.status === 201) {
			return {
				status: 201,
				message: "Historico criado com sucesso",
			}
		}
	} catch (e) {
		return {
			status: 500,
			data: e,
		}
	}
}

export async function getHistoryIdType(
	id: string,
	amount: number,
	page: number
) {
	try {
		const quantityPage = ref(1)
		let total: number

		const response = await useClient().get(
			`${HistoryRoute.GET_HISTORY_TYPEID}?typeId=${id}&amount=${amount}&page=${page}`,
			{}
		)
		if (response.status === 201) {
			total = response.data.data.total

			quantityPage.value = Math.ceil(total / amount)
			return {
				status: 201,
				message: "Historico coletado com sucesso",
				data: response.data.data.result,
				quantityPage: quantityPage.value,
			}
		}
	} catch (e) {
		return {
			status: 500,
			data: e,
		}
	}
}
