export const carouselImgTxt = [
	{
		title: "Acesse o painel de revenda",
		text: "Faça login ou preencha o formulário clicando no botão registre-se.",
	},
	{
		title: "Registre suas oportunidades",
		text: "Cadastre os produtos de interesse do seu projeto.",
	},
	{
		title: "Gerencie suas oportunidades cadastradas",
		text: "Faça o gerenciamento de todas as suas oportunidades e acompanhe o andamento das suas solicitações.",
	},
]

export const themes = [
	{
		company: "Bluebird",
		host: process.env.VUE_APP_HOST_BLUEBIRD,
		favicon: "/faviconBB.ico",
		logo: {
			img: "LOGO-BLUEBIRD.png",
			logo: "BLUEBIRD-LOGO.png",
			width: "180",
		},

		admEmail: process.env.VUE_APP_ADM_EMAIL_BLUEBIRD,

		colors: {
			defaultColor: "#00aeef",
			background: "#03a1db",
			radialGradient: "#03a1db",
			buttonBackground: "#C8F0FF",
			buttonColor: "#00aeef",
		},
	},
	{
		company: "Chainway",
		host: process.env.VUE_APP_HOST_CHAINWAY,
		favicon: "/faviconCH.ico",
		logo: {
			img: "LOGO-CHAINWAY.png",
			logo: "CHAINWAY-LOGO.png",
			width: "350",
		},

		admEmail: process.env.VUE_APP_ADM_EMAIL_CHAINWAY,

		colors: {
			defaultColor: "#4489ec",
			background: "#000",
			radialGradient: "#818181",
			buttonBackground: "#DEDEDE",
			buttonColor: "#000",
		},
	},
	{
		company: "Tas",
		host: process.env.VUE_APP_HOST_TAS,
		favicon: "/faviconTS.ico",
		logo: {
			img: "LOGO-TAS.png",
			logo: "TAS-LOGO.png",
			width: "180",
		},

		admEmail: process.env.VUE_APP_HOST_TAS,

		colors: {
			defaultColor: "#026ED2",
			background: "#026ED2",
			radialGradient: "#03a1db",
			buttonBackground: "#C8F0FF",
			buttonColor: "#026ED2",
		},
	},
	{
		company: "Sewoo",
		host: process.env.VUE_APP_HOST_SEWOO,
		favicon: "/faviconSW.ico",
		logo: {
			img: "LOGO-SEWOO.png",
			logo: "SEWOO-LOGO.png",
			width: "180",
		},

		admEmail: process.env.VUE_APP_ADM_EMAIL_SEWOO,

		colors: {
			defaultColor: "#005EAD",
			background: "#005EAD",
			radialGradient: "#03a1db",
			buttonBackground: "#C8F0FF",
			buttonColor: "#005EAD",
		},
	},
	{
		company: "Mobilebase",
		host: process.env.VUE_APP_HOST_MOBILEBASE,
		favicon: "/faviconMB.ico",
		logo: {
			img: "LOGO-MOBILEBASE.png",
			logo: "MOBILEBASE-LOGO.png",
			width: "180",
		},

		admEmail: process.env.VUE_APP_ADM_EMAIL_MOBILEBASE,

		colors: {
			defaultColor: "#01246B",
			background: "#01246B",
			radialGradient: "#03a1db",
			buttonBackground: "#C8F0FF",
			buttonColor: "#01246B",
		},
	},
	{
		company: "H3C",
		host: process.env.VUE_APP_HOST_H3C,
		favicon: "/faviconH3C.ico",
		logo: {
			img: "LOGO-H3C.png",
			logo: "H3C-LOGO.png",
			width: "180",
		},

		admEmail: process.env.VUE_APP_ADM_EMAIL_H3C,

		colors: {
			defaultColor: "#E60012",
			background: "#E60012",
			radialGradient: "#03a1db",
			buttonBackground: "#C8F0FF",
			buttonColor: "#E60012",
		},
	},
	{
		company: "Pointmobile",
		host: process.env.VUE_APP_HOST_POINTMOBILE,
		favicon: "/faviconPM.ico",
		logo: {
			img: "LOGO-POINTMOBILE.png",
			logo: "POINTMOBILE-LOGO.png",
			width: "180",
		},

		admEmail: process.env.VUE_APP_ADM_EMAIL_POINTMOBILE,

		colors: {
			defaultColor: "#B93920",
			background: "#B93920",
			radialGradient: "#03a1db",
			buttonBackground: "#C8F0FF",
			buttonColor: "#B93920",
		},
	},
	{
		company: "Cass",
		host: process.env.VUE_APP_HOST_CASS,
		favicon: "/faviconCS.ico",
		logo: {
			img: "LOGO-CASS.png",
			logo: "CASS-LOGO.png",
			width: "180",
		},

		admEmail: process.env.VUE_APP_ADM_EMAIL_CASS,

		colors: {
			defaultColor: "#3C2154",
			background: "#3C2154",
			radialGradient: "#03a1db",
			buttonBackground: "#C8F0FF",
			buttonColor: "#3C2154",
		},
	},

	{
		company: "TSC",
		host: process.env.VUE_APP_HOST_TSC,
		favicon: "/faviconTSC.ico",
		logo: {
			img: "LOGO-TSC.png",
			logo: "TSC-LOGO.png",
			width: "180",
		},

		admEmail: process.env.VUE_APP_ADM_EMAIL_TSC,

		colors: {
			defaultColor: "#134D98",
			background: "#134D98",
			radialGradient: "#03a1db",
			buttonBackground: "#E4062C",
			buttonColor: "#134D98",
		},
	},

	{
		company: "Unitech",
		host: process.env.VUE_APP_HOST_UNITECH,
		favicon: "/faviconUNI.ico",
		logo: {
			img: "LOGO-UNITECH.png",
			logo: "UNITECH-LOGO.png",
			width: "180",
		},

		admEmail: process.env.VUE_APP_ADM_EMAIL_UNITECH,

		colors: {
			defaultColor: "#005EAC",
			background: "#005EAC",
			radialGradient: "#03a1db",
			buttonBackground: "#E7372F",
			buttonColor: "#005EAC",
		},
	},
]
