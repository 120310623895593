import { useAuthClient } from "@/clients/AxiosClient"

export const getUserInfo = async (id: string) => {
	try {
		const result = await useAuthClient().get(`/user/${id}`)

		return {
			status: result.status,
			message: "Usuário recuperado com sucesso",
			data: result.data,
		}
	} catch (e: any) {
		return {
			status: e.status,
			message: "Falha ao recuperar o usuário",
		}
	}
}
