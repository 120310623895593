import axios, {
	AxiosError,
	type AxiosInstance,
	type AxiosResponse,
	type InternalAxiosRequestConfig,
} from "axios"
import { refreshToken, useClient } from "./AxiosClient"
import { Credential } from "@/utils/interfaces"
import Cookies from "js-cookie"

export class ResultResponse {
	readonly data: any
	readonly status: number

	constructor(data: any, statusCode: number) {
		this.data = data
		this.status = statusCode
	}
}

export abstract class IClient {
	abstract get(path: string, headers: any): Promise<ResultResponse>
	abstract post(path: string, data: any, headers: any): Promise<ResultResponse>
	abstract patch(path: string, data: any, headers: any): Promise<ResultResponse>
	abstract put(path: string, data: any, headers: any): Promise<ResultResponse>
}

export abstract class Interceptor {
	abstract onRequest(
		options: InternalAxiosRequestConfig
	): Promise<InternalAxiosRequestConfig>

	abstract onResponse(response: AxiosResponse): Promise<AxiosResponse>

	abstract onError(error: any): Promise<never>
}

export class RefreshTokenInterceptor implements Interceptor {
	async onRequest(
		options: InternalAxiosRequestConfig<any>
	): Promise<InternalAxiosRequestConfig<any>> {
		return options
	}

	async onResponse(
		response: AxiosResponse<any, any>
	): Promise<AxiosResponse<any, any>> {
		if (response.status === 401 && !response.config.url?.includes("/login")) {
			const url = window.location.href
			const http = window.location.protocol
			const parsedUrl = new URL(url)

			const cookieNamekeepConnection = "keep_connection"
			const keepConnection = Cookies.get(cookieNamekeepConnection)

			if (keepConnection === "false") {
				location.href = `${http}//${parsedUrl.host}/`
				const cookies = Object.keys(Cookies.get())

				// Remova cada cookie da lista
				cookies.forEach((cookie) => {
					if (cookie !== "acceptedLGPD") Cookies.remove(cookie)
				})
			}

			//OBTER REFRESH TOKEN
			const cookieNameRefreshToken = "refresh_token"
			const token = Cookies.get(cookieNameRefreshToken)

			//MÉTODO PARA REALIZAR REQUEST (REFRESH_TOKEN)
			const tokens = await refreshToken(
				process.env.VUE_APP_AUTH ? process.env.VUE_APP_AUTH : "",
				token || ""
			)

			if (tokens) {
				const company = Cookies.get("company")?.split('"')[1] || ""

				const cookieNameToken = "token"
				const cookieValueToken = JSON.stringify(`${tokens.accessToken}`)
				const expiresToken = 7

				Cookies.set(cookieNameToken, cookieValueToken, {
					expires: expiresToken,
				})

				const cookieNameRefreshToken = "refresh_token"
				const cookieValueRefreshToken = JSON.stringify(`${tokens.refreshToken}`)
				const expiresRefreshToken = 7

				Cookies.set(cookieNameRefreshToken, cookieValueRefreshToken, {
					expires: expiresRefreshToken,
				})

				// DEFINIR HOOK PARA CONFIGURAR O ACCESS_TOKEN
				useClient().setAuthorizationToken(`Bearer ${tokens.accessToken}`)

				useClient().setCompany(company)

				const instance = axios.create()

				let result

				if (response.config.baseURL?.includes(process.env.VUE_APP_AUTH || "")) {
					instance.defaults.headers.Authorization = `Bearer ${tokens.accessToken}`
					result = await instance.request({
						...response.config,
						headers: {
							...response.config.headers,
							Authorization: `Bearer ${tokens.accessToken}`,
						},
						data: response.config.data,
						baseURL: response.config.baseURL,
						method: response.config.method,
					})
				} else {
					result = await instance.request({
						...response.config,
						headers: {
							...response.config.headers,
							Authorization: `Bearer ${tokens.accessToken}`,
							company: company,
						},
						data: response.config.data,
						baseURL: response.config.baseURL,
						method: response.config.method,
					})
				}

				if (result.status === 401) {
					const cookies = Object.keys(Cookies.get())

					// Remova cada cookie da lista
					cookies.forEach((cookie) => {
						if (cookie !== "acceptedLGPD") Cookies.remove(cookie)
					})
					location.href = `${http}//${parsedUrl.host}/`
				}

				return result
			} else {
				const cookies = Object.keys(Cookies.get())

				// Remova cada cookie da lista
				cookies.forEach((cookie) => {
					if (cookie !== "acceptedLGPD") Cookies.remove(cookie)
				})
				location.href = `${http}//${parsedUrl.host}/`
			}
		}

		return response
	}

	onError(error: any): Promise<never> {
		return Promise.reject(error)
	}
}

export class AxiosClient implements IClient {
	private client: AxiosInstance

	constructor(baseUrl: string, interceptors: Array<Interceptor>) {
		this.client = axios.create({
			baseURL: baseUrl,
			validateStatus(status) {
				return status < 500
			},
		})

		interceptors.forEach((interceptor: Interceptor) => {
			this.client.interceptors.request.use(
				interceptor.onRequest,
				interceptor.onError
			)

			this.client.interceptors.response.use(
				interceptor.onResponse,
				interceptor.onError
			)
		})
	}

	setAuthorizationToken(token: string) {
		this.client.defaults.headers.Authorization = token
	}

	setCompany(company: string) {
		this.client.defaults.headers.company = company
	}

	setBasicAuthorization = (credential: Credential) => {
		this.client.defaults.auth = {
			username: credential.username.trim(),
			password: credential.password.trim(),
		}
	}

	async post(path: string, data: any, headers: any): Promise<ResultResponse> {
		try {
			const result = await this.client.post(path, data, {
				headers: headers,
			})

			return new ResultResponse(result.data, result.status)
		} catch (error) {
			if (error instanceof AxiosError) {
				return new ResultResponse(
					error.response?.data,
					error.status ? error.status : 500
				)
			}

			return new ResultResponse({ error }, 500)
		}
	}

	async get(path: string, headers: any = {}): Promise<ResultResponse> {
		try {
			const result = await this.client.get(path, {
				headers: headers,
			})

			return new ResultResponse(result.data, result.status)
		} catch (error) {
			if (error instanceof AxiosError) {
				return new ResultResponse(
					error.response?.data,
					error.status ? error.status : 500
				)
			}

			return new ResultResponse({}, 500)
		}
	}

	async put(path: string, data: any, headers: any): Promise<ResultResponse> {
		try {
			const result = await this.client.put(path, data, {
				headers: headers,
			})

			return new ResultResponse(result.data, result.status)
		} catch (error) {
			if (error instanceof AxiosError) {
				return new ResultResponse(
					error.response?.data,
					error.status ? error.status : 500
				)
			}

			return new ResultResponse({}, 500)
		}
	}

	async patch(path: string, data: any, headers: any): Promise<ResultResponse> {
		try {
			const result = await this.client.patch(path, data, {
				headers: headers,
			})
			return new ResultResponse(result.data, result.status)
		} catch (error) {
			if (error instanceof AxiosError) {
				return new ResultResponse(
					error.response?.data,
					error.status ? error.status : 500
				)
			}

			return new ResultResponse({}, 500)
		}
	}
}
