import styled from "vue3-styled-components"

export const H1 = styled.h1`
	font-style: normal;
	font-weight: 400;
	font-size: 2.5rem; //40px
	line-height: 3rem;
`
export const H2 = styled.h2`
	font-style: normal;
	font-weight: 700;
	font-size: 1rem; //16px
	line-height: 1.12rem;
	text-align: center;
`
export const ParagraphyContact = styled.p`
	font-style: normal;
	font-weight: 700;
	font-size: 0.85rem; //14px
	line-height: 0;
	@media (min-width: 1024px) and (max-width: 1279px) {
		font-size: 0.65rem; //14px
	}
`

export const Paragraphy = styled.p`
	font-style: normal;
	font-weight: 700;
	font-size: 0.85rem; //14px
	line-height: 1.12rem;
	@media (min-width: 1024px) and (max-width: 1279px) {
		font-size: 0.65rem; //14px
	}
`
export const Title = styled.h1`
	font-style: normal;
	font-weight: 600;
	font-size: 1.5rem; //24px
	line-height: 1.75rem;
	text-align: center;
	letter-spacing: -0.042rem;
`
